// Note: All process.env vars must start with 'REACT_APP_'.

import UserExperienceProvider from "../Enums/UserExperienceProvider";

// See: https://create-react-app.dev/docs/adding-custom-environment-variables/
const _environment = process.env.NODE_ENV;
const _isDevelopment = _environment === 'development'

class AppConfig {
  canChangeDebug = false
  canChangePath = false
  canChangeViews = false
  canChangeTarget = true
  showDebug = false
  showMetrics = false
  showCoords = false
  enableTool = false
  enableRecording = false
  enableUserCamera = false
  enableFlowCamera = false
  userExperienceProvider: UserExperienceProvider = 'FullStory'
}

class MockConfig extends AppConfig {
  canChangeDebug = true
  canChangePath = true
  showMetrics = true
  showCoords = true
  // enableRecording = _isDevelopment == false // Only record on production website.
  enableUserCamera = true
  enableFlowCamera = true
}

class TestConfig extends AppConfig {
  canChangeDebug = true
  canChangePath = true
  canChangeViews = true
  showDebug = true
  showMetrics = true
  enableUserCamera = true
  enableFlowCamera = true
  // enableRecording = _isDevelopment == false // Only record on production website.
  userExperienceProvider: UserExperienceProvider = 'LogRocket'
}

class ToolConfig extends AppConfig {
  enableTool = true
}


let _configClass = AppConfig

if (process.env.REACT_APP_MOCK === '1') {
  _configClass = MockConfig
} else if (process.env.REACT_APP_TEST === '1') {
  _configClass = TestConfig
} else if (process.env.REACT_APP_TOOL === '1') {
  _configClass = ToolConfig
}

export default new _configClass();
