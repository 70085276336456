import React from 'react';
import NavBar from '../Views/NavBar';
import Viewer from './Viewer';
import { CssBaseline } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import AppConfig from './AppConfig';
import Tool from './Tool';

const _enableTool = AppConfig.enableTool

let theme = createTheme({
  palette: {
    primary: {
      main: '#374282', //  Hearables purple.
    },
    secondary: {
      main: '#92be00', // Hearables green.
    },
  },
  typography: {
    h3: { // Overlay title.
      fontSize: 36,
      fontWeight: 'bold',
      color: 'white',
    },
    h4: { // Overlay message.
      fontSize: 28,
      fontWeight: 'normal',
      color: 'white',
    },
    body2: { // Hud text.
      color: '#222',
      fontWeight: 'bold',
      fontSize: 16,
      // fontFamily: 'Monospace',
    },
  },
});
theme = responsiveFontSizes(theme);

function App() {
  return (
    <div className="App">
      {/* Normalise CSS */}
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <NavBar />
        { _enableTool && <Tool />}
        {!_enableTool && <Viewer />}
      </ThemeProvider>
    </div>
  );
}

export default App;
