import React from 'react';
import { useFrame } from '@react-three/fiber';
import {
  BufferGeometry,
  CatmullRomCurve3,
  Color,
  LineBasicMaterial,
  Line,
  Vector3
} from 'three';
import { Box, CurveModifierRef } from '@react-three/drei';

const _boxSize: [number, number, number] = [4, 4, 4];

export interface Vector3d {
  x: number;
  y: number;
  z: number;
}

export interface SplineProps {
  points: Vector3d[];
  knotCount: number;
  distance?: number;
  facing?: number;
  color: Color;
}

export default function SplineModel(props: SplineProps) {
  const {
    points,
    knotCount,
    distance,
    facing = 1.0,
    color,
  } = props;
  const controlPoints = React.useMemo(
    () => points.map((hand) => new Vector3(facing * hand.x, hand.y, hand.z)),
    [points, facing]
  )
  const curveRef = React.useRef<CurveModifierRef>()
  const curve = React.useMemo(() => new CatmullRomCurve3(controlPoints, false, 'centripetal'), [controlPoints])

  const knots = React.useMemo(
    () => {
      const segmentPoints = curve.getSpacedPoints(knotCount - 1); // Note: Returns (count + 1) points.

      // Dump points for iOS app.
      let pointsString = '';
      for (const point of segmentPoints) {
        if (distance) {
          point.setLength(distance);
        }
        // Note: Swapped to convert to iOS.
        pointsString += `BasicPoseData(x: ${point.x}, y: ${point.y}, z: ${point.z}, targetSideOfHead: .left),\n`
      }
      console.log(pointsString);

      return segmentPoints; 
    },
    [curve, distance, knotCount],
  )

  const line = React.useMemo(
    () => { 
      return new Line(
        new BufferGeometry().setFromPoints(knots),
        new LineBasicMaterial({ color: color })    
      ); 
    },
    [color, knots],
  )

  // const knotBoxes = React.useMemo(
  //   () => {
  //     return knots.map((position, index) => {
  //       return <Box
  //         key={index}
  //         args={_boxSize}
  //         position={position}
  //       >
  //         <meshStandardMaterial color="hotpink" />
  //       </Box>
  //     }) 
  //   },
  //   [knots],
  // )

  const pointBoxes = React.useMemo(
    () => {
      return controlPoints.map((position, index) => {
        return <Box
          key={index}
          args={_boxSize}
          position={position}
        >
          <meshStandardMaterial color="deepskyblue" />
        </Box>
      }) 
    },
    [controlPoints],
  )

  useFrame(() => {
    if (curveRef.current) {
      curveRef.current?.moveAlongCurve(0.001)
    }
  }) 

  return (
    <>
      {/* <CurveModifier ref={curveRef} curve={curve}>
        <mesh>
          <boxBufferGeometry args={[10, 10, 10]} />
        </mesh>
      </CurveModifier> */}
      <primitive object={line} />
      {/* <group>
        { knotBoxes }
      </group> */}
      <group>
        { pointBoxes }
      </group>
    </>
  );
}
