type CameraMode = 'Camera' | 'World' | 'Behind' | 'Target' | 'User' | 'Flow';

const _defaultFov = 50.0

export function nextCameraMode(mode: CameraMode, from: CameraMode[] = []): CameraMode {
  if (from.length > 0) {
    const index = from.indexOf(mode);
    if (index !== -1) {
      const nextIndex = (index + 1) % from.length
      return from[nextIndex];
    } 
  } else {
    switch (mode) {
      case 'Camera':
        return 'World';
      case 'World':
        return 'Behind';
      case 'Behind':
        return 'Target';
      case 'Target':
        // Note: Skip user mode. Clicks are stolen by OrbitControls.
        //     return 'User'
        // case 'User':
        return 'Flow';
      case 'Flow':
        return 'Camera';
    }
  }
  return mode;
}

export function whichFov(mode: CameraMode, fov?: number): number {
  if (fov !== undefined && mode === 'Camera') {
    return fov; // Value from iPhone camera.
  } else {
    return _defaultFov;
  }
}

export type { CameraMode as default };
