class MathUtils {
  radians(degrees: number) {
    return degrees * (Math.PI / 180);
  }

  degrees(radians: number) {
    return radians / (Math.PI / 180);
  }

  verticalFov(horizontalFov: number, width: number, height: number): number {
    const hfovRad = this.radians(horizontalFov);
    const vfovRad = 2.0 * Math.atan(height * Math.tan(hfovRad / 2.0) / width);

    return this.degrees(vfovRad);
  }
}

export default new MathUtils();
