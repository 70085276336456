import React from 'react';
import {
  BufferGeometry,
  Color,
  LineBasicMaterial,
  Line,
  Quaternion,
  Vector3
} from 'three';
import AppUtils from '../Utils/AppUtils';

const _boxSize: [number, number, number] = [4, 4, 4];
const _boxCenter: [number, number, number] = [0, 0, 0]
// const _boxSize: [number, number, number] = [1, 1, 250];
// const _boxCenter: [number, number, number] = [0, 0, -_boxSize[2] * 0.5]

export interface Vector3d {
  x: number;
  y: number;
  z: number;
}

export interface Orientation {
  x: number;
  y: number;
  z: number;
}

export interface PathPoint {
  position: Vector3d;
  orientation: Orientation;
}

export interface PathSegment {
  color: Color;
  points: PathPoint[];
}

export interface PathProps {
  segments: PathSegment[];
  facing?: number;
}

interface PathRenderPoint {
  position: Vector3;
  orientation: Quaternion;
}

interface PathRenderList {
  points: PathRenderPoint[];
}

export default function PathModel(props: PathProps) {
  const {
    segments,
    facing = 1.0,
  } = props;

  const lines = React.useMemo(
    () => segments.map(segment => {
      const points = segment.points.map((point) => AppUtils.vector3(point.position));
      for (const point of points) {
        point.x *= facing;
      }
      // for (const point of points) {
      //   const distance = Math.sqrt(point.x * point.x + point.y * point.y + point.z * point.z);
      //   console.log("DIST: ", distance)
      // }

      return new Line(
        new BufferGeometry().setFromPoints(points),
        new LineBasicMaterial({ color: segment.color })
      )
    }),
    [segments, facing]
  );

  const renderLists: PathRenderList[] = React.useMemo<PathRenderList[]>(
    () => segments.map(segment => {
      const points = segment.points.map((point) => ({
        position: AppUtils.vector3(point.position),
        orientation: AppUtils.quaternion(point.orientation),
      }));
      for (const point of points) {
        point.position.x *= facing;
        point.orientation.y *= facing;
        point.orientation.z *= facing;
      }
      return {
        points: points,
      }
    }),
    [segments, facing]
  );

  return (
    <>
      <group>
        { lines.map((line, index) => (
          <primitive key={index} object={line} />
        ))}
      </group>
      <group>
        { renderLists.map(renderList => {
          return renderList.points.map((point, index) => {
            return (
              <group key={index} position={point.position} quaternion={point.orientation}>
                <mesh position={_boxCenter}> 
                  <boxBufferGeometry attach="geometry" args={_boxSize} />
                  <meshStandardMaterial attach="material" color="hotpink" />
                </mesh>
              </group>
            );
          })
        }) }
      </group>
    </>
  );
}
