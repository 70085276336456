import { Color } from 'three';
import { Vector3d } from '../Models/SplineModel';

const _defaultKnotCount = 70;

export interface SplineDefinition {
  points: Vector3d[];
  knotCount: number;
  distance?: number;
  color: Color;
}

export const arcSpline: SplineDefinition = {
  points: [
    {x: 80.83625404702929, y: -115.84097560657874, z: -168.6605122788383},
    {x: 114.75808354489082, y: -2.5882075616664566, z: -187.6802691886432},
    {x: 107.08986347259932, y: 136.85305302778576, z: -134.9185050999087},
    {x: 40.04763071013973, y: 177.55490773579288, z: -123.57362992741753},
    {x: -141.8812959832445, y: 100.0, z: -99.34635297843084},
    // {x: -111.49259612112809, y: 6.052821527281727, z: -165.92999837802049},
    {x: -56.78430914409249, y: -107.95330890782569, z: -158.49802942270196},
    {x: -31.990403168461846, y: -134.1389488628858, z: -171.4157417015117},

    // {x: 72.65849320842, y: -104.12197891394825, z: -151.59805251256523},
    // // {x: 77.5942411147856, y: -76.87751453482443, z: -155.53797496078758},
    // // {x: 107.78943191190325, y: -24.229411218826538, z: -162.2854910592392},
    // // {x: 99.63769985543112, y: -12.687623944323311, z: -170.29679314490744},
    // {x: 101.00141161795548, y: -2.277945127815941, z: -165.1820206066415},
    // // {x: 104.69038340842303, y: 29.899633298075827, z: -148.98959835484422},
    // // {x: 94.77264707429515, y: 48.65549947291916, z: -159.40788373044194},
    // // {x: 75.53030137483752, y: 53.44187412070745, z: -163.6852076575788},
    // // {x: 89.57613807075091, y: 84.35396689434964, z: -136.07082565359156},
    // // {x: 83.33314007217638, y: 75.67838863725457, z: -155.04714525953437},
    // // {x: 89.02477699679892, y: 101.63575050654026, z: -132.62795483036663},
    // // {x: 79.2781627262276, y: 109.85786852774432, z: -123.25602028771823},
    // {x: 94.79947476279787, y: 121.14683057784383, z: -119.43430502669185},
    // // {x: 82.1758568855902, y: 157.7357142194006, z: -101.85131186316286},
    // // {x: 55.995729822598534, y: 159.65667799472527, z: -123.1535628626903},
    // {x: 36.710432924762245, y: 162.75912994889958, z: -113.27615073046667},
    // // {x: 11.627972552243815, y: 170.47549912160179, z: -139.44877285228247},
    // // {x: -11.525613109207022, y: 155.96454767128873, z: -161.75552157590207},
    // // {x: -22.542093510606932, y: 150.96548994301392, z: -176.754830480379},
    // // {x: -72.00427309582416, y: 72.59348153845683, z: -163.29502335379829},
    // {x: -90.93291962890994, y: 68.82253890914953, z: -153.3719601068081},
    // // {x: -111.31888138840752, y: 46.29642723838012, z: -146.77694431287114},
    // // {x: -116.37188711129122, y: 27.823097478965174, z: -154.89042239411506},
    // {x: -110.37471453985867, y: 5.992132854352198, z: -164.26629966240955},
    // // {x: -105.14915951221825, y: -20.37690819293407, z: -170.97978612366205},
    // // {x: -117.97078613883366, y: -42.012293293724674, z: -155.75561854852108},
    // // {x: -113.84636613496802, y: -80.90351782567453, z: -150.77630057542927},
    // {x: -62.09992456230941, y: -118.05888704952385, z: -173.33513110898647},
    // {x: -33.15197448405082, y: -139.00953315911602, z: -177.6398460853283},
  ],
  knotCount: _defaultKnotCount,
  // distance: 220,
  color: new Color(0x007f7f),
}
