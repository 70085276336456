import { useState } from 'react';
import DebugMode from '../Enums/DebugMode';
import TargetMode from '../Enums/TargetMode';
import PathMode from '../Enums/PathMode';
import ViewMode from '../Enums/ViewMode';

export interface AppStateStore {
  debugMode: DebugMode[];
  targetMode: TargetMode;
  pathMode: PathMode;
  viewMode: ViewMode;
  viewCount: number;
}

export interface AppState {
  state: AppStateStore;
  setState: (values: Partial<AppStateStore>) => void;
}

export function useAppState(initial: AppStateStore): AppState {
  const [state, setState] = useState<AppStateStore>(initial);

  const setStatePartial = (values: Partial<AppStateStore>) => {
    setState(oldState => ({...oldState, ...values}));
  }

  return {
    state,
    setState: setStatePartial,
  };
}
