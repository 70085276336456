import React, { useEffect, useRef } from 'react';
import {
  extend,
  ReactThreeFiber,
  useFrame,
  useThree,
} from '@react-three/fiber';
import { PerspectiveCamera, Vector3 as T3Vector3 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { AppData } from '../Structs/AppData';
import CameraMode from '../Enums/CameraMode';

extend({ OrbitControls });

// Let typescript know about JSX element <orbitControls />
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: ReactThreeFiber.Object3DNode<
        OrbitControls,
        typeof OrbitControls
      >;
    }
  }
}

const _orbitOrigin = new T3Vector3(0, 0, 0);
const _defaultCameraDir = new T3Vector3(0, 0, -1);

interface CameraControllerProps {
  appData: AppData;
  mode: CameraMode;
  headFacing: number;
  cameraRef: React.RefObject<PerspectiveCamera>;
  domElement?: HTMLElement;
}

export default function CameraController(props: CameraControllerProps) {
  const { appData, mode, headFacing, cameraRef, domElement } =
    props;
  const controls = useRef<OrbitControls>(null);
  const { invalidate } = useThree();

  useEffect(() => {
    const current = controls.current;
    if (current !== null) {
      current.addEventListener('change', invalidate);
      return () => current.removeEventListener('change', invalidate);
    }
  }, [invalidate]);

  // Set initial camera position & orientation.
  useEffect(() => {
    if (cameraRef.current !== null) {
      cameraRef.current.position.x = 0;
      cameraRef.current.position.y = 100;
      cameraRef.current.position.z = -500;
      cameraRef.current.lookAt(0, 0, 0);
    }
  }, [cameraRef]);

  // const [lastPos] = useState<T3Vector3>(new T3Vector3());
  useFrame(() => {
    if (controls.current !== null) {
      controls.current.update();
    }

    if (cameraRef.current !== null) {
      if (mode === 'User') {
        // Code to log user camera position.
        // const pos = cameraRef.current.position
        // if (lastPos.x !== pos.x || lastPos.y !== pos.y || lastPos.z !== pos.z) {
        //     lastPos.copy(pos)
        //     console.log('POS', pos)
        // }
        // ref.current.rotateOnWorldAxis(state.scene.up, 0.01);
      } else if (mode === 'World') {
        cameraRef.current.position.x = 350 * headFacing;
        cameraRef.current.position.y = 0;
        cameraRef.current.position.z = -350;
        cameraRef.current.lookAt(0, 0, 0);
      } else if (mode === 'Behind' || mode === 'Flow') {
        // Units: Millimetres
        cameraRef.current.position.copy(appData.actual.position);
        cameraRef.current.quaternion.copy(appData.actual.quaternion);

        const direction: T3Vector3 = _defaultCameraDir
          .clone()
          .multiplyScalar(-120) // mm behind camera.
          .applyQuaternion(cameraRef.current.quaternion);
        cameraRef.current.position.add(direction);
      } else if (mode === 'Target') {
        // Units: Millimetres
        cameraRef.current.position.copy(appData.target.position);
        cameraRef.current.quaternion.copy(appData.target.quaternion);

        const direction: T3Vector3 = _defaultCameraDir
          .clone()
          .multiplyScalar(-200) // mm behind camera.
          .applyQuaternion(cameraRef.current.quaternion);
        cameraRef.current.position.add(direction);
      } else {
        // mode === 'Camera'
        // Units: Millimetres
        cameraRef.current.position.copy(appData.actual.position);
        cameraRef.current.quaternion.copy(appData.actual.quaternion);

        const direction: T3Vector3 = _defaultCameraDir
          .clone()
          .applyQuaternion(cameraRef.current.quaternion);
        cameraRef.current.position.add(direction);
      }

      cameraRef.current.updateMatrixWorld();
    }
  });

  return (
    <>
      <perspectiveCamera
        ref={cameraRef}
        // Now done in Viewport render().
        // aspect={size.width / size.height}
        // onUpdate={(self) => self.updateProjectionMatrix()}
      />
      {cameraRef.current && domElement && mode === 'User' && (
        <orbitControls
          ref={controls}
          args={[cameraRef.current, domElement]} // OR document.body
          target={_orbitOrigin}
          zoomSpeed={2}
          enablePan={true}
          enabled={mode === 'User'} // Note: Not enough to stop it stealing click and drag on tablet.
        />
      )}
    </>
  );
}
