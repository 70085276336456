import React, { useMemo, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import {
  Color as TColor,
  Euler,
  MathUtils,
  Plane,
  Quaternion,
  Ray,
  Vector3 as TVector3,
  Vector3,
} from 'three';
import { AppData, Color } from '../Structs/AppData';
import HeadModel from '../Models/HeadModel';
import TargetModel from '../Models/TargetModel';
import TorusModel from '../Models/TorusModel';
import CameraModel from '../Models/CameraModel';
import CameraController from './CameraController';
import BoxModel from '../Models/BoxModel';
import CylinderModel from '../Models/CylinderModel';
import DebugMode from '../Enums/DebugMode';
import TargetMode from '../Enums/TargetMode';
import { ViewportHook } from '../Hooks/ViewportHook';
import { whichFov } from '../Enums/CameraMode';
import ViewMode from '../Enums/ViewMode';
import FlowMode from '../Enums/FlowMode';
import PathModel from '../Models/PathModel';
import { crossLeft } from '../Structs/PathData';
import PathMode from '../Enums/PathMode';
import SplineModel from '../Models/SplineModel';
import { arcSpline } from '../Structs/SplineData';

const _defaultCameraDir = new TVector3(0, 0, -1);
const _vecZero = new TVector3();
const _cameraEdge = 0.9;
const _cameraSpaceMin = new TVector3(-_cameraEdge, -_cameraEdge, 0);
const _cameraSpaceMax = new TVector3(_cameraEdge, _cameraEdge, 1);
const _solidPhoneScale = new TVector3().setScalar(1.75);
const _hollowPhoneScale = new TVector3().setScalar(0.5);

const _earPosition = new TVector3();
const _earOrientation = new Quaternion();
const _earWidth = 30;
const _earHeight = 60;
const _earDepth = 30;

const _torusSize = 30;
const _targetColor = new TColor(0x34c759);
const _targetNormalColor = new TColor(0x5464c4);
const _targetOpacity = 1.0;
const _targetScale = new TVector3().setScalar(5);
const _targetFlowScale = new TVector3().setScalar(10);
const _cylinderColor = '#4db0e9';
const _cylinderScale = new TVector3(2, 400, 2);
const _defaultCylinderDir = new TVector3(0, 1, 0);
const _cylinderRotation = new Quaternion().setFromEuler(
  new Euler(-Math.PI * 0.5, 0, 0)
);
const _showCylinder = false;

const _getThreeColor = (color: Color): TColor => {
  return new TColor(color.r, color.g, color.b);
};

interface ViewportProps {
  appData: AppData;
  hook: ViewportHook;
  domElement?: HTMLDivElement;
  targetMode?: TargetMode;
  viewMode?: ViewMode;
  debugMode?: DebugMode[];
  pathMode?: PathMode;
  setFlowMode?: (value: FlowMode) => void;
  children?: React.ReactNode;
}

export default function Viewport(props: ViewportProps) {
  const { appData, hook, domElement, targetMode, viewMode, debugMode, pathMode, setFlowMode, children } = props;
  const { cameraMode } = hook.state;
  const threeColor = useMemo(() => _getThreeColor(appData.color), [appData.color]);
  const sceneRef = useRef<THREE.Scene>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera>(null);
  const earRef = useRef<THREE.Object3D>(null);
  const earMaterial = useRef<THREE.MeshBasicMaterial>(null);
  const targetRef = useRef<THREE.Object3D>(null);
  const targetMaterial = useRef<THREE.MeshBasicMaterial>(null);
  const actualRef = useRef<THREE.Object3D>(null);
  const actualMaterial = useRef<THREE.MeshBasicMaterial>(null);
  const rayRef = useRef<THREE.Object3D>(null);
  // const [earOpacity] = useState(0.5);
  // const [targetOpacity] = useState(0.5);
  const [earCameraSpace] = useState(() => new TVector3());
  const [actualCameraSpace] = useState(() => new TVector3());
  const [targetCameraSpace] = useState(() => new TVector3());
  const useFlow = cameraMode === 'Flow';
  const enableTarget = !useFlow && cameraMode !== 'Camera' && cameraMode !== 'User';
  const targetScale = useFlow ? _targetFlowScale : _targetScale;
  const splineData = arcSpline;
 
  let headFacing = 1;
  if (appData.target.sideOfHead === 'right') {
    headFacing *= -1;
  }
  if (viewMode === 'Mirror') {
    headFacing *= -1;
  }

  // Show cylinder in camera direction?
  const [actualPosition] = useState(() => new TVector3());
  const [cameraTarget] = useState(() => new TVector3());
  if (_showCylinder) {
    actualPosition.copy(appData.actual.position);
    cameraTarget.set(0, 0, -1);
    cameraTarget.multiplyScalar(400);
    cameraTarget.applyQuaternion(appData.actual.quaternion);
    cameraTarget.add(actualPosition);
    // cameraTarget.copy(_earPosition)
  }

  const [earRay] = useState(() => new Ray());
  const [cameraDir] = useState(() => new TVector3());
  const [phonePosition] = useState(() => new TVector3());
  const [phoneNormal] = useState(() => new TVector3());
  const [phonePlane] = useState(() => new Plane());
  const [earPositionOnPlane] = useState(() => new TVector3());

  // Manual scene render.
  useFrame(({ gl }) => {
    // Keep canvas dom element scrolling with window?
    // See: https://threejsfundamentals.org/threejs/lessons/threejs-multiple-scenes.html
    // const transform = `translateY(${window.scrollY}px)`;
    // gl.domElement.style.transform = transform;

    // Calculate ear crosshair position on actual phone plane.
    // TODO: Optimise.
    if (earRef.current && cameraRef.current) {
      // Set actual phone plane.
      phonePosition.copy(appData.actual.position);
      phoneNormal
        .copy(_defaultCameraDir)
        .applyQuaternion(appData.actual.quaternion);
      const d = phoneNormal.dot(phonePosition);
      phonePlane.set(phoneNormal, -d);

      // Set ear to camera ray.
      cameraDir.copy(cameraRef.current.position);
      cameraDir.sub(_earPosition);
      cameraDir.normalize();
      earRay.set(_earPosition, cameraDir);

      // Intersect ray with plane.
      earRay.intersectPlane(phonePlane, earPositionOnPlane);
      earRef.current.position.copy(earPositionOnPlane);

      // Calculate target fade.
      // const distance = earPositionOnPlane.sub(phonePosition).length()
      // setEarOpacity(Math.max(0, Math.min(_targetOpacity, (distance - _targetFadeThreshold) * 0.05)))
      // earRef.current.material
    }

    if (_showCylinder && rayRef.current && cameraRef.current) {
      rayRef.current.position.copy(appData.actual.position);
      rayRef.current.quaternion.copy(appData.actual.quaternion);
      rayRef.current.quaternion.multiply(_cylinderRotation);

      cameraDir.copy(_defaultCylinderDir);
      cameraDir.multiplyScalar(200).applyQuaternion(rayRef.current.quaternion);
      rayRef.current.position.add(cameraDir);

      rayRef.current.updateMatrix();
    }

    if (domElement != null) {
      const elementRect = domElement.getBoundingClientRect();
      const canvasRect = gl.domElement.getBoundingClientRect();

      // Check if element is offscreen and don't render.
      const isOffscreen =
        elementRect.top > canvasRect.bottom ||
        elementRect.bottom < canvasRect.top ||
        elementRect.left > canvasRect.right ||
        elementRect.right < canvasRect.left;

      if (!isOffscreen) {
        const left = elementRect.left - canvasRect.left;
        const bottom = canvasRect.bottom - elementRect.bottom;

        // gl.autoClear = false
        // gl.clearDepth()
        gl.setClearColor(hook.state.clearColor);
        gl.setViewport(left, bottom, elementRect.width, elementRect.height);
        gl.setScissor(left, bottom, elementRect.width, elementRect.height);
        gl.setScissorTest(true);

        const camera = cameraRef.current;
        if (camera) {
          const aspectRatio = elementRect.width / elementRect.height;

          camera.aspect = aspectRatio;
          camera.fov = whichFov(cameraMode, appData.fieldOfView);

          camera.updateProjectionMatrix();

          const target = targetRef.current
          const actual = actualRef.current
          if (useFlow && target && actual) {
            // Get ear position in camera space.
            earCameraSpace.copy(_earPosition);
            earCameraSpace.project(camera);

            // Get actual position in camera space.
            actualCameraSpace.copy(actual.position);
            actualCameraSpace.project(camera);

            // Convert to camera space range [-1, 1].
            targetCameraSpace.copy(target.position);
            targetCameraSpace.project(camera);

            // Camera space distance to ear.
            const distanceToEar = Math.sqrt(earCameraSpace.x * earCameraSpace.x + earCameraSpace.y * earCameraSpace.y);
            const showTarget = distanceToEar < 0.15;
 
            // Clamp target to screen bounds.
            target.position.copy(targetCameraSpace)
            target.position.clamp(_cameraSpaceMin, _cameraSpaceMax)
            target.position.unproject(camera)

            // Camera space distance to target.
            const distanceToTarget = Math.sqrt(targetCameraSpace.x * targetCameraSpace.x + targetCameraSpace.y * targetCameraSpace.y);
            const onTarget = distanceToTarget < 0.15;
            const tooFar = actualCameraSpace.z > targetCameraSpace.z;

            if (targetMaterial.current) {
              targetMaterial.current.opacity = MathUtils.lerp(targetMaterial.current.opacity, showTarget ? 1 : 0, 0.1);
              targetMaterial.current.color.lerp(onTarget ? _targetColor : _targetNormalColor, 0.1);
              targetMaterial.current.needsUpdate = true
              // targetMaterial.current.opacity = Math.max(0, Math.min(1, 1.4 - distanceToEar * 8.0));
            }

            if (setFlowMode) {
              let newFlowMode: FlowMode;
              if (onTarget) {
                newFlowMode = tooFar ? 'TooFar' : 'TooClose';
              } else if (showTarget) {
                newFlowMode = 'Target';
              } else {
                newFlowMode = 'Center';
              }
              setFlowMode(newFlowMode);
            }

            if (earMaterial.current) {
              earMaterial.current.color.lerp(showTarget ? _targetColor : _targetNormalColor, 0.1);
              earMaterial.current.needsUpdate = true
              // TODO: Faster update?
              // earRef.current.geometry.attributes.color.needsUpdate = true
            }

            if (actualMaterial.current) {
              actualMaterial.current.color.lerp(showTarget ? _targetColor : _targetNormalColor, 0.1);
              actualMaterial.current.needsUpdate = true
              // TODO: Faster update?
              // earRef.current.geometry.attributes.color.needsUpdate = true
            }
          } else {
            if (earMaterial.current) {
              earMaterial.current.color = _targetColor;
              earMaterial.current.needsUpdate = true;
            }
            if (actualMaterial.current) {
              actualMaterial.current.color = _targetColor;
              actualMaterial.current.needsUpdate = true;
            }
          }
        }

        if (sceneRef.current && cameraRef.current) {
          gl.render(sceneRef.current, cameraRef.current);
        }
      }
    }
  }, hook.state.priority);

  const lightPosition = new Vector3(5 * headFacing, 2, -3);

  return (
    <scene ref={sceneRef}>
      <ambientLight intensity={0.2} />
      {/* <pointLight position={[200 * headFacing, 100, 0]} intensity={0.8}/> */}
      <directionalLight position={lightPosition} intensity={0.8} />
      <CameraController
        cameraRef={cameraRef}
        appData={appData}
        mode={cameraMode}
        headFacing={headFacing}
        domElement={domElement}
      />

      {debugMode && debugMode.includes('Axis') && <axesHelper scale={500} />}

      {/* Head */}
      {<HeadModel facing={headFacing}/>}

      {/* Ear Box */}
      {debugMode && debugMode.includes('Box') && (
        <BoxModel
          position={[0, 0, _earDepth * 0.5]}
          orientation={_earOrientation}
          scale={[_earWidth, _earHeight, _earDepth]}
          opacity={1.0}
          color="orange"
        />
      )}

      {/* Target Torus */}
      {enableTarget && targetMode === 'Torus' && (
        <TorusModel
          filename={'./torus-vertical.stl'}
          position={appData.target.position}
          orientation={appData.target.quaternion}
          scale={[_torusSize, _torusSize, _torusSize]}
          opacity={appData.color.alpha}
          color={threeColor}
        />
      )}

      {/* Actual Phone Torus */}
      {enableTarget && targetMode === 'Torus' && (
        <TorusModel
          filename={'./torus-vertical.stl'}
          position={appData.actual.position}
          orientation={appData.actual.quaternion}
          scale={[_torusSize, _torusSize, _torusSize]}
          opacity={appData.color.alpha}
          color={threeColor}
        />
      )}

      {/* Target Plane Box */}
      {enableTarget && targetMode === 'Solid' && (
        <CameraModel
          filename="./iphone.stl"
          position={appData.target.position}
          orientation={appData.target.quaternion}
          scale={_solidPhoneScale}
          opacity={appData.color.alpha}
          color={threeColor}
        />
      )}

      {/* Actual Phone Box */}
      {enableTarget && targetMode === 'Solid' && (
        <CameraModel
          filename="./iphone.stl"
          position={appData.actual.position}
          orientation={appData.actual.quaternion}
          scale={_solidPhoneScale}
          opacity={appData.color.alpha}
          color={threeColor}
        />
      )}

      {/* Target Plane Box */}
      {enableTarget && targetMode === 'Hollow' && (
        <CameraModel
          filename="./Iphone11_Rectangular-Cut.STL"
          position={appData.target.position}
          orientation={appData.target.quaternion}
          scale={_hollowPhoneScale}
          opacity={appData.color.alpha}
          color={threeColor}
        />
      )}

      {/* Actual Phone Box */}
      {enableTarget && targetMode === 'Hollow' && (
        <CameraModel
          filename="./Iphone11_Rectangular-Cut.STL"
          position={appData.actual.position}
          orientation={appData.actual.quaternion}
          scale={_hollowPhoneScale}
          opacity={appData.color.alpha}
          color={threeColor}
        />
      )}

      {/* Ear Crosshair */}
      {/* TODO: Use a billboard instead */}
      {(useFlow || cameraMode === 'Behind') && (
        <TargetModel
          filename={'./torus-vertical.stl'}
          objectRef={earRef}
          materialRef={earMaterial}
          position={_vecZero}
          orientation={appData.actual.quaternion}
          scale={targetScale}
          opacity={_targetOpacity}
          color={_targetColor}
        />
      )}

      {/* Actual Crosshair */}
      {/* TODO: Use a billboard instead */}
      {(useFlow || cameraMode === 'Behind') && (
        <TargetModel
          objectRef={actualRef}
          materialRef={actualMaterial}
          filename={'./torus-vertical.stl'}
          position={appData.actual.position}
          orientation={appData.actual.quaternion}
          scale={targetScale}
          opacity={_targetOpacity}
          color={_targetColor}
        />
      )}

      {/* Target Crosshair */}
      {/* TODO: Use a billboard instead */}
      {useFlow && (
        <TargetModel
          filename={'./torus-vertical.stl'}
          objectRef={targetRef}
          materialRef={targetMaterial}
          position={appData.target.position}
          orientation={appData.actual.quaternion}
          scale={targetScale}
          opacity={_targetOpacity}
          color={_targetColor}
        />
      )}

      {/* Line from Actual in Camera direction */}
      {cameraMode === 'Target' && _showCylinder && (
        <CylinderModel
          objectRef={rayRef}
          position={appData.actual.position}
          orientation={appData.actual.quaternion}
          scale={_cylinderScale}
          color={_cylinderColor}
        />
      )}

      {pathMode === 'SixStep' && <PathModel segments={crossLeft} facing={headFacing} />}

      {(pathMode === 'SixStep' || pathMode === 'Arc') && 
          <SplineModel
            points={splineData.points}
            knotCount={splineData.knotCount}
            distance={splineData.distance}
            color={splineData.color}
            facing={headFacing}
          />
      }

      {/* Children passed from calling code */}
      {children}

    </scene>
  );
}
