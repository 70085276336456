import React from 'react';
import { Vector3 } from '@react-three/fiber';
import { Quaternion } from 'three';

interface BoxProps {
  position?: Vector3;
  orientation?: Quaternion;
  scale?: Vector3;
  opacity: number;
  color: string;
}

export default function BoxModel(props: BoxProps) {
  const {
    position,
    orientation,
    scale,
    opacity,
    color,
  } = props;
  return (
    <mesh position={position} scale={scale} quaternion={orientation}>
      <boxBufferGeometry args={[1, 1, 1]} />
      <meshStandardMaterial
        transparent={true}
        color={color}
        opacity={opacity}
      />
    </mesh>
  );
}
