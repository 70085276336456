import React, { Suspense, useMemo } from 'react';
import { Vector3, useLoader } from '@react-three/fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { Color, Euler, MeshBasicMaterial, Object3D, Quaternion } from 'three';

const _rotation = new Euler(0, 0, Math.PI * 0.5);

interface TargetModelProps {
  filename: string;
  position: Vector3;
  orientation: Quaternion;
  scale: Vector3;
  opacity: number;
  color: Color;
  objectRef?: React.Ref<Object3D>;
  materialRef?: React.Ref<MeshBasicMaterial>;
}

/*
 * If you come across any issues getting materials to work, refer to:
 * https://github.com/pmndrs/react-three-fiber/discussions/897#discussioncomment-247458
 */

function Model(props: TargetModelProps) {
  const { filename, position, orientation, scale, opacity, color, objectRef, materialRef } =
    props;
  const torusLoader = useLoader(STLLoader, filename);
  const torus = useMemo(() => torusLoader.clone(), [torusLoader]);

  return (
    <group
      ref={objectRef}
      position={position}
      scale={scale}
      quaternion={orientation}
      renderOrder={50}
    >
      <mesh
        rotation={_rotation}
      >
        <primitive object={torus} attach="geometry" />
        <meshBasicMaterial
          ref={materialRef}
          color={color}
          transparent
          opacity={opacity}
          depthTest={false}
          depthWrite={false} // Let CylinderModel draw over us.
        />
      </mesh>
    </group>
  );
}

export default function TargetModel(props: TargetModelProps) {
  return (
    <Suspense fallback={null}>
      <Model {...props} />
    </Suspense>
  );
}
