import { faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import GridLayout from 'react-grid-layout';
import React from 'react';
import HudLabel from './HudLabel';
import { nextCameraMode, whichFov } from '../Enums/CameraMode';
import { ViewportHook } from '../Hooks/ViewportHook';
import AppConfig from '../Main/AppConfig';
import { AppData, ScanMetrics } from '../Structs/AppData';
import { Statistics } from '../Structs/Statistics';
import EarUtils from '../Utils/EarUtils';

const _showDebug = AppConfig.showDebug;
const _showMetrics = AppConfig.showMetrics
const _showCoords = AppConfig.showCoords

// Colors that work on light or dark.
const _red = '#f44'
const _green = '#2c8'
const _blue = '#0af'

interface ViewportElementProps {
  hook: ViewportHook;
  fov?: number;
  layout?: GridLayout.Layout;
  children?: React.ReactNode;
  metrics?: ScanMetrics;
  appData?: AppData;
  statistics?: Statistics;
}

const ViewportElement = React.forwardRef(function ViewportElement(
  props: ViewportElementProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const {
    hook,
    fov,
    layout,
    metrics,
    statistics,
    appData,
  } = props;
  const { cameraMode, cameraModes } = hook.state;
  const canChangeCamera = cameraModes && cameraModes.length > 1;
  const onCameraModeClick = () => {
    hook.setState((state) => ({
      ...state,
      cameraMode: nextCameraMode(state.cameraMode, cameraModes)
    }));
  };

  const sortedMetrics = metrics && Object.entries(metrics).sort(
    // Sort by key name.
    ([keyA, ], [keyB, ]) => keyA.localeCompare(keyB)
  ).map(([key, value]) => {
    return (
      <Typography key={key} variant="body2" style={{color: _blue}}>
        {`${key.toUpperCase()} ${value}`}
      </Typography>
    );
  })

  // All frames should be ear recognised, otherwise show error color.
  const earColor = (statistics?.earsPerSecond == statistics?.messagesPerSecond) ? _green : _red;
  return (
    <div style={hook.state.style}>

      {/* Div used for OrbitControls clicks */}
      <div ref={ref} style={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
      }} />

      { _showDebug && <HudLabel>
        {`FOV ${whichFov(cameraMode, fov).toFixed(2)}`}
        {layout != undefined && `AR ${layout.w} / ${layout.h}`}
      </HudLabel> }
 
      { _showMetrics && sortedMetrics && <div style={{
        left: 10,
        top: 10,
        position: 'absolute',
      }}>
        { sortedMetrics }
        {/* <Typography key={'mps'} variant="body2" style={{color: _green}}>
          {`MPS ${statistics?.messagesPerSecond?.toFixed(2)}`}
        </Typography> */}
        <Typography key={'ear'} variant="body2" style={{color: earColor}}>
          {`EAR ${statistics?.earsPerSecond?.toFixed(2)}`}
        </Typography>

        { _showCoords && appData && <Typography key={'left'} variant="body2" style={{color: _blue}}>
          {`LEFT ${EarUtils.leftAngle(appData.actual.position).toFixed(0)}°`}
        </Typography> }
  
        { _showCoords && appData && <Typography key={'up'} variant="body2" style={{color: _blue}}>
          {`UP ${EarUtils.upAngle(appData.actual.position).toFixed(0)}°`}
        </Typography> }
  
        { _showCoords && appData && <Typography key={'dist'} variant="body2" style={{color: _blue}}>
          {`DIST ${EarUtils.distanceTo(appData.actual.position).toFixed(0)}mm`}
        </Typography> }

      </div> }

      {props.children}

      { canChangeCamera && <Button
        variant="outlined"
        onClick={onCameraModeClick}
        style={{
          position: 'absolute',
          right: 0,
          margin: 10,
        }}
      >
        <FontAwesomeIcon
          icon={faRedoAlt}
          size="1x"
          color="rgba(0, 0, 0, 0.6)"
          style={{ marginRight: 10 }}
        />
        {hook.state.cameraMode}
      </Button> }

      {/* Display camera name when camera mode can't be changed */}
      { !canChangeCamera && <div style={{
        right: 10,
        top: 10,
        position: 'absolute',
      }}>
        <Typography variant="button">
          { hook.state.cameraMode.toUpperCase() }
        </Typography>
      </div> }
    </div>
  );
});

export default ViewportElement;