import { Color } from 'three';
import { PathSegment } from '../Models/PathModel'

export const crossLeft: PathSegment[] = [
  // Original six step
  // {
  //   color: new Color(0xff00ff),
  //   points: [
  //     {position: {x: -0.0, y: 0.0, z: -200.0}, orientation: {x: -0.0, y: -0.0, z: 0.0}},
  //     {position: {x: 70.0, y: 0.0, z: -187.34993995195194}, orientation: {x: -0.0, y: 0.3575711036455103, z: 0.0}},
  //     {position: {x: 40.0, y: -90.0, z: -174.0689518552921}, orientation: {x: 0.46676533904729656, y: 0.2258727251393929, z: 0.0}},
  //     {position: {x: 10.0, y: 140.0, z: -142.47806848775008}, orientation: {x: -0.775397496610753, y: 0.07007133173326693, z: 0.0}},
  //     {position: {x: -90.0, y: 90.0, z: -154.27248620541513}, orientation: {x: -0.46676533904729633, y: -0.5281117666417205, z: 0.0}},
  //     {position: {x: -120.0, y: 0.0, z: -160.0}, orientation: {x: -0.0, y: -0.6435011087932844, z: 0.0}},
  //   ],
  // },
  // Phil - original steps
  {
    color: new Color(0x00ff00),
    points: [
      {position: {x: 72.65849320842, y: -104.12197891394825, z: -151.59805251256523}, orientation: {x: 0.5545371756024876, y: 2.694654914616151, z: 0.0}},
      {position: {x: 77.5942411147856, y: -76.87751453482443, z: -155.53797496078758}, orientation: {x: 0.4164202478292604, y: 2.67884424562133, z: 0.0}},
      {position: {x: 107.78943191190325, y: -24.229411218826538, z: -162.2854910592392}, orientation: {x: 0.12373235874401453, y: 2.5553022150547, z: 0.0}},
      {position: {x: 99.63769985543112, y: -12.687623944323311, z: -170.29679314490744}, orientation: {x: 0.06421669738405288, y: 2.6122140055736947, z: 0.0}},
      {position: {x: 101.00141161795548, y: -2.277945127815941, z: -165.1820206066415}, orientation: {x: 0.011764851222769023, y: 2.592792734659515, z: 0.0}},
      {position: {x: 104.69038340842303, y: 29.899633298075827, z: -148.98959835484422}, orientation: {x: -0.1627472421380793, y: 2.5290776083218427, z: 0.0}},
      {position: {x: 94.77264707429515, y: 48.65549947291916, z: -159.40788373044194}, orientation: {x: -0.2565779162629953, y: 2.605205485597694, z: 0.0}},
      {position: {x: 75.53030137483752, y: 53.44187412070745, z: -163.6852076575788}, orientation: {x: -0.2881993054698216, y: 2.7092690781492452, z: 0.0}},
      {position: {x: 89.57613807075091, y: 84.35396689434964, z: -136.07082565359156}, orientation: {x: -0.4777859258670383, y: 2.5594010876593787, z: 0.0}},
      {position: {x: 83.33314007217638, y: 75.67838863725457, z: -155.04714525953437}, orientation: {x: -0.4060433055907947, y: 2.6484205181803437, z: 0.0}},
      {position: {x: 89.02477699679892, y: 101.63575050654026, z: -132.62795483036663}, orientation: {x: -0.566664949497797, y: 2.5504327140918845, z: 0.0}},
      {position: {x: 79.2781627262276, y: 109.85786852774432, z: -123.25602028771823}, orientation: {x: -0.6432605724565328, y: 2.57001324085869, z: 0.0}},
      {position: {x: 94.79947476279787, y: 121.14683057784383, z: -119.43430502669185}, orientation: {x: -0.671370117917378, y: 2.470682052536693, z: 0.0}},
      {position: {x: 82.1758568855902, y: 157.7357142194006, z: -101.85131186316286}, orientation: {x: -0.8782242991539746, y: 2.4627059083133367, z: 0.0}},
      {position: {x: 55.995729822598534, y: 159.65667799472527, z: -123.1535628626903}, orientation: {x: -0.8678391358832243, y: 2.714851856873197, z: 0.0}},
      {position: {x: 36.710432924762245, y: 162.75912994889958, z: -113.27615073046667}, orientation: {x: -0.9391690925665643, y: 2.8281939028436844, z: 0.0}},
      {position: {x: 11.627972552243815, y: 170.47549912160179, z: -139.44877285228247}, orientation: {x: -0.8834779254674037, y: 3.058399850727217, z: 0.0}},
      {position: {x: -11.525613109207022, y: 155.96454767128873, z: -161.75552157590207}, orientation: {x: -0.7659083808972804, y: -3.0704595825194505, z: 0.0}},
      {position: {x: -22.542093510606932, y: 150.96548994301392, z: -176.754830480379}, orientation: {x: -0.7028856046638919, y: -3.0147443009681196, z: 0.0}},
      {position: {x: -72.00427309582416, y: 72.59348153845683, z: -163.29502335379829}, orientation: {x: -0.38632475431312474, y: -2.726293568312079, z: 0.0}},
      {position: {x: -90.93291962890994, y: 68.82253890914953, z: -153.3719601068081}, orientation: {x: -0.36836873873850867, y: -2.60641644417721, z: 0.0}},
      {position: {x: -111.31888138840752, y: 46.29642723838012, z: -146.77694431287114}, orientation: {x: -0.24621745394006278, y: -2.4927231416768714, z: 0.0}},
      {position: {x: -116.37188711129122, y: 27.823097478965174, z: -154.89042239411506}, orientation: {x: -0.14263850261957828, y: -2.4972488688850323, z: 0.0}},
      {position: {x: -110.37471453985867, y: 5.992132854352198, z: -164.26629966240955}, orientation: {x: -0.030268729439394494, y: -2.549958154628726, z: 0.0}},
      {position: {x: -105.14915951221825, y: -20.37690819293407, z: -170.97978612366205}, orientation: {x: 0.10117006963901047, y: -2.5902311552985364, z: 0.0}},
      {position: {x: -117.97078613883366, y: -42.012293293724674, z: -155.75561854852108}, orientation: {x: 0.21179390034605083, y: -2.493366301721692, z: 0.0}},
      {position: {x: -113.84636613496802, y: -80.90351782567453, z: -150.77630057542927}, orientation: {x: 0.4045947527779202, y: -2.4948558801718774, z: 0.0}},
      {position: {x: -62.09992456230941, y: -118.05888704952385, z: -173.33513110898647}, orientation: {x: 0.5701597607038641, y: -2.7975737928391506, z: 0.0}},
      {position: {x: -33.15197448405082, y: -139.00953315911602, z: -177.6398460853283}, orientation: {x: 0.6557104302956408, y: -2.9570904565425624, z: 0.0}},
    ],
  },
  
  // Triangle
  // {
  //   color: new Color(0xff00ff),
  //   points: [
  //     {position: {x: 55.9371569572069, y: 196.66091706654032, z: -110.76990096507305}, orientation: {x: -1.0078961707710798, y: 2.6739648686428477, z: 0.0}},
  //     {position: {x: 166.56970252702908, y: 17.044993897808403, z: -182.87592767010972}, orientation: {x: -0.06879770857667436, y: 2.4028237905998657, z: 0.0}},
  //     {position: {x: -114.52858784267335, y: 89.70632510119619, z: -188.70443814576421}, orientation: {x: -0.3860019869519327, y: -2.5961000988491736, z: 0.0}},
  //     // More extreme back point.
  //     // {position: {x: -141.22254532086245, y: 33.932148325615245, z: -143.9413422475426}, orientation: {x: -0.1667103035550219, y: -2.3657283571019803, z: 0.0}},
  //   ],
  // },
  // Cross - Bottom to top - 19 points
  // {
  //   color: new Color(0x00ff00),
  //   points: [
  //     {position: {x: 68.40402866513374, y: -93.96926207859082, z: -162.75953626987473}, orientation: {x: 0.4891166663891173, y: 0.39786311404758007, z: -0.0}},
  //     {position: {x: 68.40402866513375, y: -79.42625239342055, z: -170.3301479278293}, orientation: {x: 0.40838892617759437, y: 0.38188210123590877, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: -64.27876096865391, z: -176.6044443118978}, orientation: {x: 0.3272010156831351, y: 0.3695356336033197, z: -0.0}},
  //     {position: {x: 68.40402866513372, y: -48.64206936033878, z: -181.5346742380737}, orientation: {x: 0.24567421093290998, y: 0.3603563240174116, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: -32.63518223330696, z: -185.08331567966462}, orientation: {x: 0.1639088582414554, y: 0.3540148965055686, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: -16.379921663817868, z: -187.2233613325718}, orientation: {x: 0.08199144334736275, y: 0.3502929735816338, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 0.0, z: -187.93852415718166}, orientation: {x: -0.0, y: 0.3490658503988659, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 16.379921663817868, z: -187.2233613325718}, orientation: {x: -0.08199144334736275, y: 0.3502929735816338, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 32.63518223330696, z: -185.08331567966462}, orientation: {x: -0.1639088582414554, y: 0.3540148965055686, z: -0.0}},
  //     {position: {x: 68.40402866513372, y: 48.64206936033878, z: -181.5346742380737}, orientation: {x: -0.24567421093290998, y: 0.3603563240174116, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 64.27876096865391, z: -176.6044443118978}, orientation: {x: -0.3272010156831351, y: 0.3695356336033197, z: -0.0}},
  //     {position: {x: 68.40402866513375, y: 79.42625239342055, z: -170.3301479278293}, orientation: {x: -0.40838892617759437, y: 0.38188210123590877, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 93.96926207859082, z: -162.75953626987473}, orientation: {x: -0.4891166663891169, y: 0.39786311404758007, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 107.79710893915124, z: -153.9502262640114}, orientation: {x: -0.5692322789824287, y: 0.4181250948698365, z: -0.0}},
  //     {position: {x: 68.40402866513375, y: 120.80455471101072, z: -143.96926207859084}, orientation: {x: -0.6485391150942832, y: 0.4435539073911463, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 132.89260487773493, z: -132.89260487773493}, orientation: {x: -0.7267750543196971, y: 0.47536334624702803, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 143.96926207859082, z: -120.80455471101072}, orientation: {x: -0.8035808813259888, y: 0.5152238417162064, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 153.9502262640114, z: -107.79710893915126}, orientation: {x: -0.8784511939461637, y: 0.5654463563522163, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 162.7595362698747, z: -93.96926207859084}, orientation: {x: -0.9506573206628421, y: 0.6292328865890737, z: -0.0}},      
  //   ],
  // },
  // Cross - left to right - 16 points
  // {
  //   color: new Color(0x00ff00),
  //   points: [
  //     {position: {x: 84.52365234813988, y: 0.0, z: -181.26155740732997}, orientation: {x: -0.0, y: 0.4363323129985824, z: -0.0}},
  //     {position: {x: 68.40402866513374, y: 0.0, z: -187.93852415718166}, orientation: {x: -0.0, y: 0.3490658503988659, z: -0.0}},
  //     {position: {x: 51.76380902050414, y: 0.0, z: -193.18516525781362}, orientation: {x: -0.0, y: 0.2617993877991494, z: -0.0}},
  //     {position: {x: 34.72963553338607, y: 0.0, z: -196.96155060244166}, orientation: {x: -0.0, y: 0.17453292519943292, z: -0.0}},
  //     {position: {x: 17.431148549531635, y: 0.0, z: -199.23893961834912}, orientation: {x: -0.0, y: 0.08726646259971646, z: -0.0}},
  //     {position: {x: -0.0, y: 0.0, z: -200.0}, orientation: {x: -0.0, y: -0.0, z: -0.0}},
  //     {position: {x: -17.431148549531635, y: 0.0, z: -199.23893961834912}, orientation: {x: -0.0, y: -0.08726646259971646, z: -0.0}},
  //     {position: {x: -34.72963553338607, y: 0.0, z: -196.96155060244166}, orientation: {x: -0.0, y: -0.17453292519943292, z: -0.0}},
  //     {position: {x: -51.76380902050414, y: 0.0, z: -193.18516525781362}, orientation: {x: -0.0, y: -0.2617993877991494, z: -0.0}},
  //     {position: {x: -68.40402866513374, y: 0.0, z: -187.93852415718166}, orientation: {x: -0.0, y: -0.3490658503988659, z: -0.0}},
  //     {position: {x: -84.52365234813988, y: 0.0, z: -181.26155740732997}, orientation: {x: -0.0, y: -0.4363323129985824, z: -0.0}},
  //     {position: {x: -100.0, y: 0.0, z: -173.20508075688778}, orientation: {x: -0.0, y: -0.5235987755982987, z: -0.0}},
  //     {position: {x: -114.71528727020922, y: 0.0, z: -163.8304088577984}, orientation: {x: -0.0, y: -0.6108652381980152, z: -0.0}},
  //     {position: {x: -128.55752193730785, y: 0.0, z: -153.20888862379567}, orientation: {x: -0.0, y: -0.6981317007977316, z: -0.0}},
  //     {position: {x: -141.4213562373095, y: 0.0, z: -141.4213562373095}, orientation: {x: -0.0, y: -0.7853981633974483, z: -0.0}},
  //     {position: {x: -153.20888862379562, y: 0.0, z: -128.5575219373078}, orientation: {x: -0.0, y: -0.8726646259971651, z: -0.0}},                          
  //   ],
  // },
];
