import { useCallback, useEffect, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { Pose, AppData, ScreenEdge, BBox, VideoConfig, AppDataDefault } from '../Structs/AppData';
import { MessageData } from '../Structs/MessageData';
import Subscriber from '../Components/Subscriber';
import MathUtils from '../Utils/MathUtils';
import AppUtils from '../Utils/AppUtils';

const _bboxEdgeThreshold = 0.2;
const _connectionLostTimeMs = 2000;
const _defaultFov = 45.36; // Default value (from iPhone XR).

const wrongEarErrors = ['wrongEar', 'WRNG'];
const tooCloseErrors = [
  'tooCloseToEar',
  'bboxTooTall', 
  'invalidBBoxDepth',
  'tooFewKeypoints',
  'TCLS',
  'BBTL',
  'BBDP',
  'FWKP'
];
const tooFarErrors = ['tooFarFromEar', 'TFAR'];
const leftScreenErrors = ['closeToScreenLeft', 'CTSL'];
const rightScreenErrors = ['closeToScreenRight', 'CTSR'];
const topScreenErrors = ['closeToScreenTop', 'CTST'];
const bottomScreenErrors = ['closeToScreenBottom', 'CTSB'];

function _calculateFov(config?: VideoConfig): number {
  if (config !== undefined) {
      // Note: iOS cropped aspect in app is 4 / 5 = 0.8
      // Note: iOS width and height are uncropped values in landscape mode eg. 1920 x 1080.
      return MathUtils.verticalFov(config.hfov, config.width * 400.0, config.height * 500.0)
  } else {
    return _defaultFov;
  }
}

function _transformPose(value: any, coordSystemVersion: number): Pose {
  if (coordSystemVersion === 2) {
    // New coordinate system
    return {
      position: AppUtils.vector3(value.position),
      quaternion: AppUtils.quaternion(value.orientation),
      sideOfHead: value.sideOfHead,
    };
  } else {
    const adjustedPosition = new Vector3(
      -value.position.x,
      value.position.z,
      -value.position.y
    );
    
    const adjustedOrientation = new Vector3(
      value.orientation.x + Math.PI,
      -value.orientation.z,
      -value.orientation.y + Math.PI
    );
    
    return {
      position: AppUtils.vector3(adjustedPosition),
      quaternion: AppUtils.quaternion(adjustedOrientation, 'XZY'),
      sideOfHead: value.sideOfHead,
    };
  }
}

function _transformBBox(value: Array<number>): BBox {
  return {
    xmin: value[0],
    ymin: value[1],
    xmax: value[2],
    ymax: value[3],
  };
}

function setHasAny<T>(set: Set<T>, vals: T[]): boolean {
  for (const val of vals) {
    if (set.has(val)) { return true; }
  }
  return false;
}

export default function useMessaging() {
  const messageCount = useRef<number>(0);
  const earCount = useRef<number>(0);
  const errorCount = useRef<number>(0);
  const lastTime = useRef<number>(0);
  const [appData, setAppData] = useState<AppData>(() => (AppDataDefault));

  const dataReceived = useCallback((data: any) => {
    const message = data.value as MessageData;
    const time: number | undefined = message.time;
    const prevTime = lastTime.current;
    const isFinished = message.isFinished;
    const isEarUpdate = message?.messageType !== 'progressUpdate';
    const canSkipMessage = isFinished !== true;
    const coordSystemVersion = message.coordSystemVersion ?? 1;
    const targetPose: Pose = _transformPose(message.targetPose, coordSystemVersion);

    messageCount.current += 1;
    if (isEarUpdate) {
      earCount.current += 1;
    } else {
      errorCount.current += 1;
    }

    if (time) {
      lastTime.current = time;

      // Throw away non-guaranteed messages that come out of sequence.
      if (canSkipMessage && time < prevTime) {
        // console.log(`Skipping time ${time} < ${prevTime}`)
        return; // Skip it.
      }
    }

    // console.log("Received New Message: ", message);
    const canSkip = message.canSkip && isFinished !== true;
    let posesPerSide = 0;
    let currPoseNum = -1;
    const hasPoseCount = 'posesPerSide' in message;
    const hasPoseProgress = 'currPoseNum' in message;
    if (hasPoseCount && hasPoseProgress) {
      posesPerSide = message.posesPerSide;
      currPoseNum = message.currPoseNum;
    }

    if (isEarUpdate) {
      // If the message is a full pose update
      const actualPose = _transformPose(message.actualPose, coordSystemVersion);
      setAppData((appData) => ({
        ...appData,
        actual: actualPose,
        target: targetPose,
        color: message.planeColor,
        isTracking: true,
        isWrongEar: false,
        isOutOfView: message.outOfView?.isOutOfView ?? false,
        outOfViewAngle: -(message.outOfView?.angle ?? 0.0),
        fieldOfView: _calculateFov(message.video),
        posesPerSide: posesPerSide,
        currPoseNum: currPoseNum,
        status: 'InProgress',
        time: time,
        canSkip: canSkip,
        nearScreenEdge: 'None',
        latestBBox: Object.prototype.hasOwnProperty.call(message, 'bbox')
          ? _transformBBox(message.bbox)
          : appData.latestBBox,
        metrics: message.scanMetrics,
      }));
    } else {
      // If the message is just an intermediate progress update
      setAppData((appData) => {
        let isOutOfView = appData.isOutOfView;
        let outOfViewAngle = appData.outOfViewAngle;

        isOutOfView = message.outOfView?.isOutOfView ?? false;
        outOfViewAngle = -(message.outOfView?.angle ?? 0.0);

        let isWrongEar = appData.isWrongEar;
        let nearScreenEdge: ScreenEdge = appData.nearScreenEdge;
        if (Object.prototype.hasOwnProperty.call(message, 'errors')) {
          const errors: Array<string> = message.errors;
          const errorSet = new Set(errors);
          const { xmin, ymin, xmax, ymax } = appData.latestBBox;

          if (setHasAny(errorSet, wrongEarErrors)) {
            isWrongEar = true;
          } else if (setHasAny(errorSet, tooCloseErrors)) {
            nearScreenEdge = 'Near';
          } else if (setHasAny(errorSet, tooFarErrors)) {
            nearScreenEdge = 'Far';
          } else if (setHasAny(errorSet, leftScreenErrors) ||
            xmin < _bboxEdgeThreshold
          ) {
            nearScreenEdge = 'Left';
          } else if (setHasAny(errorSet, rightScreenErrors) ||
            xmax > 1 - _bboxEdgeThreshold
          ) {
            nearScreenEdge = 'Right';
          } else if (setHasAny(errorSet, topScreenErrors) ||
            ymin < _bboxEdgeThreshold
          ) {
            nearScreenEdge = 'Top';
          } else if (setHasAny(errorSet, bottomScreenErrors) ||
            ymax > 1 - _bboxEdgeThreshold
          ) {
            nearScreenEdge = 'Bottom';
          }
        }

        const actual = appData.actual;
        /*
        const lastPose = message.lastPose;
        const attitude = message.attitude;

        // Roll device orientation change into 'actual' pose.
        if (lastPose && attitude) {
          const diff = new Quaternion(
            -attitude.x,
            attitude.y,
            -attitude.z,
            attitude.w
          );
          actual = _transformPose(lastPose, coordSystemVersion);
          actual.quaternion.multiply(diff);
        }
        */

        let status = appData.status;

        // Gets last message from iPhone -> isFinished.
        if (isFinished === true) {
          status = 'Finished';
        } else if (status === 'NotConnected' || status === 'ConnectionLost') {
          status = 'NotStarted';
        } else if (isFinished === false && status !== 'InProgress') {
          status = 'NotStarted';
        }

        return {
          ...appData,
          isTracking: false,
          actual: actual,
          target: targetPose,
          isWrongEar: isWrongEar,
          isOutOfView: isOutOfView,
          outOfViewAngle: outOfViewAngle,
          fieldOfView: _calculateFov(message.video),
          posesPerSide: posesPerSide,
          currPoseNum: currPoseNum,
          status: status,
          time: time,
          canSkip: canSkip,
          nearScreenEdge: nearScreenEdge,
          metrics: message.scanMetrics,
        };
      });
    }
  }, []);

  const startScan = useCallback(
    (code: string) => {
      Subscriber.subscribe(code, {
        next: dataReceived,
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          console.log(`Unsubscribing from topic: ${code}`);
        },
      });
      // console.log(`PubSub result: ${JSON.stringify(sub, null, 2)}`)

      // console.log(`New Scan State: ${'Not Started'}`)
      setAppData((appData) => ({
        ...appData,
        status: 'NotConnected',
        canSkip: false,
        // TODO: Re-init all members???
      }));
    },
    [dataReceived]
  );

  const stopScan = useCallback(() => {
    Subscriber.unsubscribe();
  }, []);

  // Watch for connection lost.
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;

    // Note: Legacy iPhone app's will have 'time=undefined'.
    if (appData.time) {
      timer = setTimeout(() => {
        setAppData((appData) => ({
          ...appData,
          status:
            appData.status !== 'Finished' ? 'ConnectionLost' : 'Finished',
        }));
      }, _connectionLostTimeMs);
    }

    return () => {
      if (timer !== undefined) {
        clearTimeout(timer);
      }
    };
  }, [appData.time]);

  return { appData, messageCount, earCount, errorCount, startScan, stopScan };
}
