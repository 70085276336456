import * as FullStory from '@fullstory/browser';
import LogRocket from 'logrocket';
import AppConfig from '../Main/AppConfig';
import AppEvent from './AppEvent';

const _enableRecording = AppConfig.enableRecording;
const _userExperienceProvider = AppConfig.userExperienceProvider;
const _label = _enableRecording ? "ON" : "OFF";

class Recording {
  init() {
    console.log(`Recording[${_label}]: init`)
    if (_enableRecording) {
      switch (_userExperienceProvider) {
        case 'FullStory':
          FullStory.init({ orgId: '161Y7J' });
          break; 
        case 'LogRocket':
          LogRocket.init('q02jzy/web-mirror-app-mock');
          break;
      }
    }
  }

  event(name: AppEvent, properties: { [key: string]: any }) {
    console.log(`Recording[${_label}]:`, name, properties)
    if (_enableRecording) {
      switch (_userExperienceProvider) {
        case 'FullStory':
          FullStory.event(name, properties);
          break;
        case 'LogRocket':
          LogRocket.track(name, properties);
          break;
      }
    }
  }
}

export default new Recording();
