import { Euler, Quaternion, Vector3 } from 'three';

const _tempEuler = new Euler();

// iOS class used to represent positions.
export interface Vector3d {
  x: number;
  y: number;
  z: number;
}

// iOS class used to represent rotations.
export interface Rotation {
  x: number;
  y: number;
  z: number;
}

class AppUtils {
  // Convert iOS to ThreeJS.
  vector3(position: Vector3d): Vector3 {
    return new Vector3(position.x, position.y, position.z);
  }

  // Convert iOS to ThreeJS.
  quaternion(rotation: Rotation, order = 'ZYX'): Quaternion {
    _tempEuler.set(rotation.x, rotation.y, rotation.z, order);
    return new Quaternion().setFromEuler(_tempEuler);
  }
}

export default new AppUtils();
