import { useEffect, useState, MutableRefObject } from 'react';
import { Statistics } from '../Structs/Statistics';
import useDelta from './Delta';

export default function useStatistics(
  messageCount: MutableRefObject<number>,
  earCount: MutableRefObject<number>,
  errorCount: MutableRefObject<number>
): Statistics {
  // Update FPS (actually 'Messages Per Second').
  const [statistics, setStatistics] = useState<Statistics>({
    messagesPerSecond: 0,
    earsPerSecond: 0,
    errorsPerSecond: 0,
  });
  const messageDelta = useDelta(messageCount);
  const earDelta = useDelta(earCount);
  const errorDelta = useDelta(errorCount);
  useEffect(() => {
    let lastTime = Date.now();
    const interval = setInterval(() => {
      const thisTime = Date.now();
      const timeDelta = thisTime - lastTime;
      const fps = 1000.0 / timeDelta;

      setStatistics({
        messagesPerSecond: messageDelta() * fps,
        earsPerSecond: earDelta() * fps,
        errorsPerSecond: errorDelta() * fps,
      });

      lastTime = thisTime;
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [earDelta, errorDelta, messageDelta]);

  return statistics;
}
