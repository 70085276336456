import { useState } from 'react';
import CameraMode from '../Enums/CameraMode';

export interface ViewportHookState {
  cameraMode: CameraMode;
  cameraModes?: CameraMode[];
  clearColor: THREE.Color;
  priority: number;
  style: React.CSSProperties;
}

export interface ViewportHook {
  state: ViewportHookState;
  setState: React.Dispatch<React.SetStateAction<ViewportHookState>>;
}

export function useViewportHook(initial: ViewportHookState): ViewportHook {
  const [state, setState] = useState<ViewportHookState>(initial);
  return {
    state,
    setState
  };
}
