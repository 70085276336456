import Amplify from 'aws-amplify';
import { AWSIoTProvider, PubSub } from '@aws-amplify/pubsub';
import { Observer } from 'zen-observable-ts';
import AppError from '../Errors/AppError';

// Much more verbose logging from AWS.
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

enum CodeLength {
  Hearables = 5,
}

const _iotRegionDefault = 'ap-southeast-2';
const _iotRegions: { [key: string]: string } = {
  A: 'ap-southeast-2', // Sydney, Australia
  C: 'us-west-1', // North California, USA
  G: 'eu-central-1', // Frankfurt, Germany
  J: 'ap-northeast-1', // Tokyo, Japan
  K: 'ap-northeast-2', // Seoul, South Korea
  L: 'eu-west-2', // London, UK
  O: 'us-east-2', // Ohio, USA
  R: 'us-west-2', // Oregon, USA
};

// API: https://docs.amplify.aws/lib/pubsub/subunsub/q/platform/js#subscribe-to-a-topic
// iOS: https://github.com/aws-amplify/aws-sdk-ios/blob/main/AWSIoT/AWSIoTDataManager.h
// New JS?: https://github.com/aws/aws-sdk-js-v3
class Subscriber {
  _setupTopicPrefix?: string;
  _setupTopicLength?: number;
  _subscription?: ZenObservable.Subscription;

  _setupHearables(prefix: string) {
    // TODO: Look at using a lower level API to eg. get a confirmed connection. Relevant AWS source code:
    // https://github.com/aws-amplify/amplify-js/blob/main/packages/pubsub/src/Providers/MqttOverWSProvider.ts
    Amplify.configure({
      Auth: {
        region: 'ap-southeast-2', // Sydney, AU.
        identityPoolId: 'ap-southeast-2:1665654e-d5cd-4320-bd37-9496de954b6d', // Hearables - 'mirror_app_pool'
      },
    });

    let iotRegion = _iotRegionDefault;

    // Select IoT endpoint by prefix (region).
    if (prefix in _iotRegions) {
      iotRegion = _iotRegions[prefix];
    }

    console.log(`Setting IoT region: ${iotRegion}`);

    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: iotRegion,
        aws_pubsub_endpoint: `wss://ats61w8ubgn7e-ats.iot.${iotRegion}.amazonaws.com/mqtt`,
      })
    );
  }

  subscribe(topic: string, observer: Observer<any>): Subscriber {
    this.unsubscribe();

    const hL = CodeLength.Hearables;
    const oldTopicLength = this._setupTopicLength;
    const newTopicLength = topic.length;
    const oldTopicPrefix = this._setupTopicPrefix;
    const newTopicPrefix = newTopicLength === hL ? topic.slice(0, 1) : '';

    if (newTopicLength !== hL) {
      // Must be a valid length.
      throw new AppError(`Code must be ${hL} characters.`);
    }

    if (oldTopicLength && newTopicLength !== oldTopicLength) {
      // Have already run setup with a different length topic.
      throw new AppError(`Code length change. Please refresh browser.`);
    }

    if (oldTopicPrefix?.length && newTopicPrefix !== oldTopicPrefix) {
      // Have already run setup with a different prefix.
      throw new AppError(`Code prefix changed. Please refresh browser.`);
    }

    if (!oldTopicLength) {
      // First time setup.
      this._setupHearables(newTopicPrefix);
    }

    this._setupTopicLength = newTopicLength;
    this._setupTopicPrefix = newTopicPrefix;

    console.log(`Subscribing to topic: ${topic}`);
    this._subscription = PubSub.subscribe(topic).subscribe(observer);
    return this;
  }

  unsubscribe() {
    if (this._subscription !== undefined) {
      console.log('Unsubscribing');
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }
  }
}

export default new Subscriber();
