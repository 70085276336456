import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Step, Stepper, StepLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import TargetMode from '../Enums/TargetMode';
import PathMode from '../Enums/PathMode';
import ViewMode from '../Enums/ViewMode';
import AppConfig from '../Main/AppConfig';
import { AppData } from '../Structs/AppData';
import { AppState } from './AppState';
import DebugMode from '../Enums/DebugMode';
import EarUtils from '../Utils/EarUtils';

const _canChangeDebug = AppConfig.canChangeDebug;
const _canChangePath = AppConfig.canChangePath;
const _canChangeTarget = AppConfig.canChangeTarget;
const _canChangeViews = AppConfig.canChangeViews;

const StyledStepper = styled(Stepper)({
  marginTop: 10,
  marginBottom: 10,
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  position: 'relative',
  pointerEvents: 'auto',
  left: 0,
  bottom: 0,
  marginTop: 10,
  marginBottom: 10,
  '&:not(:first-of-type)': {
    marginRight: 20,
  },
});

const StyledToggleButton = styled(ToggleButton)({
  padding: '5px 15px', // Matches @mui Button.
});

interface FooterProps {
  appData: AppData;
  appState: AppState;
}

export default function Footer(props: FooterProps) {
  const { appData, appState } = props;

  const { viewCount } = appState.state;
  const onViewCountChange = (value: number) => {
    if (value != null) {
      appState.setState({viewCount: value});
    }
  };

  const { debugMode } = appState.state;
  const onDebugModeChange = (newMode: DebugMode[]) => {
    appState.setState({debugMode: newMode});
  };

  const { targetMode } = appState.state;
  const onTargetModeChange = (value: TargetMode) => {
    if (value !== null) {
      appState.setState({targetMode: value});
    }
  };

  const { pathMode } = appState.state;
  const onPathModeChange = (value: PathMode) => {
    if (value !== null) {
      appState.setState({pathMode: value});
    }
  };

  const { viewMode } = appState.state;
  const onViewModeChange = (value: ViewMode) => {
    if (value !== null) {
      appState.setState({viewMode: value});
    }
  };

  let steps: number[] = [];
  const { posesPerSide: poseCount, status: scanStatus } = appData;
  const showProgress = poseCount > 0 && poseCount <= 10; // Note: Rendering too slow with too many poses.
  const currPose = scanStatus === 'Finished' ? poseCount : (appData.currPoseNum - 1);
  if (showProgress) {
    steps = Array.from(Array(poseCount).keys());
  }

  return (
  <>

        { showProgress && (
          <StyledStepper activeStep={currPose} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{''}</StepLabel>
              </Step>
            ))}
          </StyledStepper>
        )}

        {/* Targetting Mode */}
        {_canChangeTarget && (
          <StyledToggleButtonGroup
            color="primary"
            value={targetMode}
            exclusive
            onChange={(event: any, newMode: TargetMode) =>
              onTargetModeChange(newMode)
            }
          >
            <StyledToggleButton style={{ width: 90 }} value="Solid">
              Solid
            </StyledToggleButton>
            <StyledToggleButton style={{ width: 90 }} value="Hollow">
              Hollow
            </StyledToggleButton>
            <StyledToggleButton style={{ width: 90 }} value="Torus">
              Target
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        )}

        {/* View Mode */}
        <StyledToggleButtonGroup
          color="primary"
          value={viewMode}
          exclusive
          onChange={(event: any, newMode: ViewMode) =>
            onViewModeChange(newMode)
          }
        >
          <StyledToggleButton style={{ width: 90 }} value="Mirror">
            Mirror
          </StyledToggleButton>
          <StyledToggleButton style={{ width: 90 }} value="Normal">
            Off
          </StyledToggleButton>
        </StyledToggleButtonGroup>

        {/* Path Mode */}
        {_canChangePath && (
          <StyledToggleButtonGroup
            color="primary"
            value={pathMode}
            exclusive
            onChange={(event: any, newMode: PathMode) =>
              onPathModeChange(newMode)
            }
          >
            <StyledToggleButton style={{ width: 90 }} value="Arc">
              Arc
            </StyledToggleButton>
            <StyledToggleButton style={{ width: 90 }} value="SixStep">
              Steps
            </StyledToggleButton>
            <StyledToggleButton style={{ width: 90 }} value="Hide">
              Off
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        )}

        {/* View Count */}
        {_canChangeViews && (
          <StyledToggleButtonGroup
            color="primary"
            value={viewCount}
            exclusive
            onChange={(event: any, newValue: number) =>
              onViewCountChange(newValue)
            }
          >
            <StyledToggleButton style={{ width: 90 }} value={1}>
              One
            </StyledToggleButton>
            <StyledToggleButton style={{ width: 90 }} value={2}>
              Two
            </StyledToggleButton>
            <StyledToggleButton style={{ width: 90 }} value={3}>
              Three
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        )}

        {/* Debug Mode */}
        {_canChangeDebug && (
          <StyledToggleButtonGroup
            color="primary"
            value={debugMode}
            onChange={(event: any, newMode: DebugMode[]) =>
              onDebugModeChange(newMode)
            }
          >
            <StyledToggleButton style={{ width: 90 }} value="Axis">
              Axis
            </StyledToggleButton>
            <StyledToggleButton style={{ width: 90 }} value="Box">
              Box
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        )}

        {_canChangeDebug && (<Button
          variant="outlined"
          onClick={() => {
            const leftAngle = EarUtils.leftAngle(appData.actual.position)
            const upAngle = EarUtils.upAngle(appData.actual.position)
            const distance = appData.actual.position.length()
            const side = "." + appData.actual.sideOfHead
            console.log(`BasicPoseData(degreesUp: ${upAngle}, degreesLeft: ${leftAngle}, targetDist: ${distance}, targetSideOfHead: ${side}),`)
          }}
        >
          {'Log Pose'}
        </Button>
      )}
    </>
  );
}