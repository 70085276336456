import React, { useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Color } from 'three';
import { styled } from '@mui/material/styles';
import Viewport from '../Scene/Viewport';
import ViewportElement from '../Views/ViewportElement';
import { useViewportHook, ViewportHookState } from '../Hooks/ViewportHook';
import { AppData, AppDataDefault } from '../Structs/AppData';
import DebugMode from '../Enums/DebugMode';
import PathModel from '../Models/PathModel';
import { crossLeft } from '../Structs/PathData';
import SplineModel from '../Models/SplineModel';
import { arcSpline } from '../Structs/SplineData';

const _viewBackground = new Color(0xffcccccc);
const _debugMode: DebugMode[] = ['Box', 'Axis'];

const _rightConfig: ViewportHookState = {
  cameraMode: 'User',
  cameraModes: ['User'],
  clearColor: _viewBackground,
  priority: 10,
  style: {
    width: '100%',
    height: '100%',
  },
};

const _canvasStyle: React.CSSProperties = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: -1000, // Must be behind UI elements, eg. <input>'s.
};

const RootComponent = styled('div')({
  position: 'relative',
  width: '100%',
  height: '800px',
});

export default function Tool() {
  const rightHook = useViewportHook(_rightConfig);
  const [rightElement, setRightElement] = useState<HTMLDivElement>();
  const ref = useRef<HTMLDivElement>(null);
  const appData: AppData = AppDataDefault;
  const splineData = arcSpline;

  return (
    <RootComponent ref={ref}>

      {/*
        Note: Canvas covers entire screen. Then viewports track DOM element locations/sizes.
        Note: linear={true} also switches off tone mapping (same as flat={true}).
      */}
      <Canvas linear={true} style={_canvasStyle}>
        <Viewport
          key="vp1"
          appData={appData}
          hook={rightHook}
          domElement={rightElement}
          debugMode={_debugMode}
        >

          <PathModel segments={crossLeft} />

          <SplineModel
            points={splineData.points}
            knotCount={splineData.knotCount}
            distance={splineData.distance}
            color={splineData.color}
          />

        </Viewport>
      </Canvas>

      <ViewportElement
        ref={(value) => setRightElement(value || undefined)}
        hook={rightHook}
      />

    </RootComponent>
  );
}
