import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Color, Vector3, Quaternion } from 'three';
import GridLayout from 'react-grid-layout';
import { AppData, Pose } from '../Structs/AppData';
import AppConfig from '../Main/AppConfig';
import { SceneOverlay, SceneOverlayMessage } from '../Views/SceneOverlay';
import EdgeMessageOverlay from '../Views/EdgeMessageOverlay';
import Viewport from './Viewport';
import ViewportElement from '../Views/ViewportElement';
import { useViewportHook, ViewportHookState } from '../Hooks/ViewportHook';
import useBounds from '../Hooks/Bounds';
import { Statistics } from '../Structs/Statistics';
import FlowMode, { flowText } from '../Enums/FlowMode';
import { AppState } from '../Main/AppState';
import CameraMode from '../Enums/CameraMode';

const _canChangeViews = AppConfig.canChangeViews;
const _enableUserCamera = AppConfig.enableUserCamera
const _enableFlowCamera = AppConfig.enableFlowCamera

const _gridColumns = 14;
const _gridMinW = 1;
const _gridMargin: [number, number] = [0, 10];
const _gridLayout: GridLayout.Layout[] = [
  { i: 've1', x: 4, y: 0, w: 10, h: 8, minW: _gridMinW, static: !_canChangeViews },
  { i: 've2', x: 0, y: 0, w: 4, h: 5, minW: _gridMinW, static: !_canChangeViews },
  { i: 've3', x: 0, y: 5, w: 4, h: 3, minW: _gridMinW, static: !_canChangeViews },
];

const _viewBackground = new Color(0xffcccccc);
const _rightCameraModes: CameraMode[] = ['Behind', 'Target'];

if (_enableFlowCamera) {
  _rightCameraModes.push('Flow')
}

if (_enableUserCamera) {
  _rightCameraModes.push('User')
}

const _topConfig: ViewportHookState = {
  cameraMode: 'Camera',
  clearColor: _viewBackground,
  priority: 10,
  style: {
    width: '100%',
    height: '100%',
  },
};

const _bottomConfig: ViewportHookState = {
  cameraMode: 'World',
  clearColor: _viewBackground,
  priority: 10,
  style: {
    width: '100%',
    height: '100%',
  },
};

const _rightConfig: ViewportHookState = {
  cameraMode: 'Behind',
  cameraModes: _rightCameraModes,
  clearColor: _viewBackground,
  priority: 10,
  style: {
    width: '100%',
    height: '100%',
  },
};

const _canvasStyle: React.CSSProperties = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: -1000, // Must be behind UI elements, eg. <input>'s.
};

interface SceneProps {
  appData: AppData;
  appState: AppState;
  statistics: Statistics;
}

function _mirrorPose(pose: Pose): Pose {
  // Note: Could just alter values in-place to save allocations.
  const position = pose.position;
  const quaternion = pose.quaternion;
  return {
    position: new Vector3(-position.x, position.y, position.z),
    quaternion: new Quaternion(quaternion.x, -quaternion.y, -quaternion.z, quaternion.w),
    sideOfHead: pose.sideOfHead,
  };
}

export default function Scene(props: SceneProps) {
  const { appData: appDataIn, appState, statistics } = props;
  const { debugMode, pathMode, targetMode, viewCount, viewMode } = appState.state;

  // Refresh grid layout on view count change.
  useEffect(() => {
    setGridLayout(_gridLayout);
  }, [viewCount])

  // Mirror appData if required.
  const appData = useMemo(() => {
    if (viewMode === 'Mirror') {
      return {
        ...appDataIn,
        actual: _mirrorPose(appDataIn.actual),
        target: _mirrorPose(appDataIn.target),
        outOfViewAngle: -appDataIn.outOfViewAngle,
      }
    } else {
      return appDataIn; // No change.
    }
  }, [appDataIn, viewMode]);

  const { status: scanStatus, isOutOfView } = appData;
  // const wrongSideOfHead = appData.actual.sideOfHead !== appData.target.sideOfHead
  const topHook = useViewportHook(_topConfig);
  const [topElement, setTopElement] = useState<HTMLDivElement>();
  const bottomHook = useViewportHook(_bottomConfig);
  const [bottomElement, setBottomElement] = useState<HTMLDivElement>();
  const rightHook = useViewportHook(_rightConfig);
  const [rightElement, setRightElement] = useState<HTMLDivElement>();
  const ref = useRef<HTMLDivElement>(null);
  const bounds = useBounds(ref);

  const [gridLayout, setGridLayout] =
    useState<GridLayout.Layout[]>(_gridLayout);
  function onLayoutChange(layout: GridLayout.Layout[]) {
    if (_canChangeViews) {
      setGridLayout(layout);
    }
  }
 
  const flowMode = useRef<FlowMode>('Center');
  const setFlowMode = (value: FlowMode) => {
    flowMode.current = value;
  }

  // Determine which overlay (if any) to display on the screen
  let overlayTitle = '';
  let overlayMessage = '';
  let overlayInternet = '';
  let overlayFlow = '';
  let overlayEdge = false;
  let overlayArrow = false;
  const overlaysEnabled = rightHook.state.cameraMode !== 'User';
  if (scanStatus === 'Finished') {
    overlayTitle = 'Scan Complete!';
  } else if (scanStatus === 'NotConnected') {
    overlayTitle = 'Not Connected';
    overlayMessage = 'Please enter the code from the iPhone app.';
  } else if (scanStatus === 'ConnectionLost') {
    overlayTitle = 'Connection Lost';
    overlayMessage = 'Please check your iPhone and web internet connections.';
  } else if (scanStatus === 'NotStarted' || appData.isWrongEar) {
    overlayTitle = `Please point your phone at your ${appData.target.sideOfHead} ear`;
  } else if (scanStatus === 'InProgress' && appData.nearScreenEdge !== 'None') {
    overlayEdge = true;
  } else if (
    (rightHook.state.cameraMode === 'Camera' ||
    rightHook.state.cameraMode === 'Behind') &&
    isOutOfView
  ) {
    overlayArrow = true;
  } else {
    if (statistics.messagesPerSecond < 25) {
      overlayInternet = 'Internet Slow';
    }
    if (rightHook.state.cameraMode === 'Flow') {
      overlayFlow = flowText(flowMode.current);
    }
  }

  return (
    <div ref={ref} style={{position: 'relative'}}>
      {/*
        Note: Canvas covers entire screen. Then viewports track DOM element locations/sizes.
        Note: linear={true} also switches off tone mapping (same as flat={true}).
      */}
      <Canvas linear={true} style={_canvasStyle}>
        <Viewport
          key="vp1"
          appData={appData}
          hook={rightHook}
          domElement={rightElement}
          targetMode={targetMode}
          viewMode={viewMode}
          debugMode={debugMode}
          pathMode={pathMode}
          setFlowMode={setFlowMode}
        />

        {viewCount >= 2 && (
          <Viewport
            key="vp2"
            appData={appData}
            hook={topHook}
            domElement={topElement}
            targetMode={targetMode}
            viewMode={viewMode}
          />
        )}

        {viewCount >= 3 && (
          <Viewport
            key="vp3"
            appData={appData}
            hook={bottomHook}
            domElement={bottomElement}
            targetMode={targetMode}
            viewMode={viewMode}
          />
        )}
      </Canvas>

      {/* These items define the layout of components */}
      <GridLayout
        // layout={[...gridLayout]}
        isDraggable={_canChangeViews}
        isResizable={_canChangeViews}
        cols={_gridColumns}
        containerPadding={_gridMargin}
        rowHeight={bounds?.width / _gridColumns}
        width={bounds?.width || 1280}
        onLayoutChange={onLayoutChange}
      >
        {[
          <div key="ve1" data-grid={gridLayout.find((e) => e.i == 've1')}>
            <ViewportElement
              ref={(value) => setRightElement(value || undefined)}
              hook={rightHook}
              fov={appData.fieldOfView}
              layout={gridLayout.find((e) => e.i == 've1')}
            >
              {overlaysEnabled && <SceneOverlay show={overlayArrow} opacity={0.0}>
                <img
                  src="./circle-arrow.png"
                  alt="Arrow for guidance when the target position is out of view"
                  style={{
                    height: '40%',
                    margin: 'auto',
                    display: 'block',
                    transform: `rotate(${appData.outOfViewAngle}rad)`,
                    opacity: 0.8,
                  }}
                />
              </SceneOverlay>}

              {overlaysEnabled && <SceneOverlayMessage
                show={!!overlayInternet}
                opacity={0.8}
                title={overlayInternet}
                style={{ height: '80px' }}
              />}

              {overlaysEnabled && <SceneOverlayMessage
                show={!!overlayFlow}
                opacity={0.8}
                title={overlayFlow}
                style={{ height: '80px', bottom: 0 }}
              />}

              {overlaysEnabled && <SceneOverlayMessage
                show={!!overlayTitle}
                opacity={0.8}
                title={overlayTitle}
                msg={overlayMessage}
              />}

              {overlaysEnabled && <EdgeMessageOverlay
                show={overlayEdge}
                opacity={0.6}
                nearScreenEdge={appData.nearScreenEdge}
                sideOfHead={appData.actual.sideOfHead}
              />}

            </ViewportElement>
          </div>,
          <div key="ve2" data-grid={gridLayout.find((e) => e.i == 've2')}>
            <ViewportElement
              ref={(value) => setTopElement(value || undefined)}
              hook={topHook}
              fov={appData.fieldOfView}
              layout={gridLayout.find((e) => e.i == 've2')}
              metrics={appData.metrics}
              appData={appData}
              statistics={statistics}
            />
          </div>,
          <div key="ve3" data-grid={gridLayout.find((e) => e.i == 've3')}>
            <ViewportElement
              ref={(value) => setBottomElement(value || undefined)}
              hook={bottomHook}
              fov={appData.fieldOfView}
              layout={gridLayout.find((e) => e.i == 've3')}
            />
          </div>,
        ].slice(0, viewCount)}
      </GridLayout>

    </div>
  );
}
