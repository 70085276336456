import React from 'react';
import { Fade, Typography } from '@mui/material';

export interface SceneOverlayProps {
  opacity: number;
  show?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

interface SceneOverlayMessageProps extends SceneOverlayProps {
  title: string;
  msg?: string;
}

export function SceneOverlay(props: SceneOverlayProps) {
  const {
    opacity,
    show,
    style,
    children,
  } = props;
  return (
    <Fade in={show} timeout={300}>
      <div
        style={{
          // TODO: Put Hud buttons ABOVE overlay but other elements (eg. text + crosshair) BELOW overlay.
          pointerEvents: 'none', // Allow click through overlay to Hud buttons.
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 10,
          display: 'flex',
          backgroundColor: `rgba(1, 1, 1, ${opacity})`,
          padding: 20,
          ...style,
        }}
      >
        {children}
      </div>
    </Fade>
  );
}

export function SceneOverlayMessage(props: SceneOverlayMessageProps) {
  const {
    title,
    msg,
    ...rest
  } = props
  return (
    <SceneOverlay {...rest}>
      <div style={{ margin: 'auto', display: 'block', textAlign: 'center' }}>
        <div style={{ margin: 'auto' }}>
        <Typography variant="h3">
          {title}
        </Typography>
        </div>
        {msg && (
          <div style={{ margin: '1em auto auto' }}>
          <Typography variant="h4">
            {msg}
          </Typography>
          </div>
        )}
      </div>
    </SceneOverlay>
  );
}
