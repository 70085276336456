import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
}));

interface ConnectingDialogProps {
  open: boolean;
  onCancel: () => void;
}

export default function ConnectingDialog(props: ConnectingDialogProps) {
  const { open, onCancel } = props;

  return (
    <Dialog
      open={open}
      // onClose={onCancel} // Cancel on click away/Escape.
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Connecting...'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Waiting for messages from the iPhone app.
        </DialogContentText>
        <StyledBox>
          <CircularProgress />
        </StyledBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
