import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'
import App from './Main/App';
import Recording from './Recording/Recording';
// import reportWebVitals from './Main/reportWebVitals';

Recording.init();

ReactDOM.render(
  // Note: <StrictMode> causes 2 x renders in development mode.
  // See: https://github.com/facebook/react/issues/16362
  <React.StrictMode>
    <App />
  </React.StrictMode>
  , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
