import { Vector3, Quaternion } from 'three';

export type SideOfHead = 'left' | 'right';

export interface Pose {
  position: Vector3;
  quaternion: Quaternion;
  sideOfHead: SideOfHead;
}

export interface Color {
  r: number;
  g: number;
  b: number;
  alpha: number;
}

export interface BBox {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

export interface ScanMetrics {
  // Note: Don't need to list ALL here. They are iterated by Hud.tsx anyway.
  cpu?: string;
  mem?: string;
  pps?: string;
}

export interface VideoConfig {
  width: number;
  height: number;
  hfov: number;
}

/* The status of the user's scan.
 *   NotConnected    => No messages have been received from the iOS app yet
 *   NotStarted      => The user has not yet pointed the phone at their ear
 *   InProgress      => The user is in the middle of scanning their ear
 *   Finished        => The uer has finished scanning their ear
 *   ConnectionLost  => Was connected but connection was lost
 */
export type ScanStatus =
  | 'NotConnected'
  | 'NotStarted'
  | 'InProgress'
  | 'Finished'
  | 'ConnectionLost';

export type ScreenEdge =
  | 'None'
  | 'Near'
  | 'Far'
  | 'Top'
  | 'Bottom'
  | 'Left'
  | 'Right';

// Data from the iOS app.
export interface AppData {
  actual: Pose; // iPhone pos/rot relative to ear.
  target: Pose; // Current target pos/rot relative to ear.
  color: Color; // Current target color.
  isTracking: boolean; // Ear is recognised and being tracked.
  isWrongEar: boolean;
  isOutOfView: boolean;
  outOfViewAngle: number;
  posesPerSide: number;
  currPoseNum: number;
  status: ScanStatus;
  time?: number; // Millisecond time since scan start.
  fieldOfView: number; // Vertical field of view.
  canSkip: boolean;
  nearScreenEdge: ScreenEdge;
  latestBBox: BBox;
  metrics?: ScanMetrics;
}

export const AppDataDefault: AppData = {
  actual: {
    position: new Vector3(0, 0, -200),
    quaternion: new Quaternion(0, 1, 0, 0),
    sideOfHead: 'left',
  },
  target: {
    position: new Vector3(0, 0, -150),
    quaternion: new Quaternion(0, 1, 0, 0),
    sideOfHead: 'left',
  },
  color: { r: 0.0, g: 0.0, b: 0.0, alpha: 0.5 },
  isTracking: false,
  isWrongEar: false,
  isOutOfView: false,
  outOfViewAngle: 0.0,
  fieldOfView: 50.0,
  posesPerSide: 0,
  currPoseNum: -1,
  status: 'NotConnected',
  time: undefined,
  canSkip: false,
  nearScreenEdge: 'None',
  latestBBox: { xmin: 0.5, ymin: 0.5, xmax: 0.5, ymax: 0.5 },
  metrics: undefined,
};
