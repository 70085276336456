import React, { useEffect, useRef, useState } from 'react';
import { Container } from '@mui/material';
import ConnectingDialog from '../Dialogs/ConnectingDialog';
import PreviewCodeForm from '../Views/PreviewCodeForm';
import Scene from '../Scene/Scene';
import useMessaging from '../Hooks/Messaging';
import useStatistics from '../Hooks/Statistics';
import Recording from '../Recording/Recording';
import AppEvent from '../Recording/AppEvent';
import Footer from './Footer';
import SceneGui from '../Views/SceneGui';
import { useAppState } from './AppState';

export default function Viewer() {
  const appState = useAppState({
    debugMode: [],
    targetMode: 'Solid',
    pathMode: 'Hide',
    viewMode: 'Normal',
    viewCount: 3,
  });
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [connecting, setConnecting] = useState(false);
  const { appData, messageCount, earCount, errorCount, startScan, stopScan } = useMessaging();
  const statistics = useStatistics(messageCount, earCount, errorCount);

  const handleSubmitCodeForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    startScan(code);
    setConnecting(true);
    Recording.event(AppEvent.connecting, {code_str: code})
  };

  const onCancelConnection = () => {
    stopScan();
    setConnecting(false);
    setError('Connection cancelled.');
    Recording.event(AppEvent.connectionCancelled, {code_str: code})
  };

  // Clear error when code changes.
  useEffect(() => {
    setError('');
  }, [code]);

  // Hide connecting dialog when a message arrives.
  useEffect(() => {
    if (connecting) {
      const isConnected = appData.status !== 'NotConnected';
      setConnecting(!isConnected);
    }
  }, [appData.status, connecting]);

  // Record app events.
  useEffect(() => {
    Recording.event(AppEvent.statusChanged, {status_str: appData.status})
  }, [appData.status]);

  // Report pose and time taken.
  const prevTime = useRef<number>(Date.now());
  useEffect(() => {
    const thisTime = Date.now();
    const elapsedTime = thisTime - prevTime.current;
    prevTime.current = thisTime;

    Recording.event(AppEvent.poseChanged, {
      curr_pose_int: appData.currPoseNum,
      time_taken_real: elapsedTime / 1000.0,
    })
  }, [appData.currPoseNum]);

  let uiMessage = '';
  if (appData.canSkip) {
    uiMessage = 'Having trouble? Use the skip button on the iPhone.';
  }

  return (
    <Container style={{ maxWidth: 1280, marginTop: 30 }}>
      <PreviewCodeForm
        code={code}
        error={error}
        setCode={setCode}
        handleSubmitCodeForm={handleSubmitCodeForm}
      />

      <div style={{
        marginTop: 10,
      }}>
        <strong>Live Preview</strong>
      </div>

      {/* UI Elements - That are shown above overlay */}
      {!!uiMessage && <SceneGui msg={uiMessage} />}

      <Scene
        appData={appData}
        appState={appState}
        statistics={statistics}
      />
      <Footer
        appData={appData}
        appState={appState}
      />
      <ConnectingDialog open={connecting} onCancel={onCancelConnection} />
    </Container>
  );
}
