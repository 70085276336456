import React, { Suspense, useMemo } from 'react';
import { useLoader, Vector3 } from '@react-three/fiber';
import { Color, Quaternion } from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

interface CameraModelProps {
  filename: string;
  position: Vector3;
  orientation: Quaternion;
  scale: Vector3;
  opacity: number;
  color: Color;
}

function Model(props: CameraModelProps) {
  const { filename, position, orientation, scale, opacity, color } = props;
  const phoneLoader = useLoader(STLLoader, filename);
  const phone = useMemo(() => phoneLoader.clone(), [phoneLoader]);
  return (
    <group position={position} scale={scale} quaternion={orientation}>
      <mesh>
        <primitive object={phone} attach="geometry" />
        <meshStandardMaterial
          color={color}
          transparent
          opacity={opacity}
          // depthWrite={false} // Let CylinderModel draw over us.
        />
      </mesh>
    </group>
  );
}

export default function CameraModel(props: CameraModelProps) {
  return (
    <Suspense fallback={null}>
      <Model {...props} />
    </Suspense>
  );
}
