import React, { useEffect, useMemo, Suspense } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three-stdlib/loaders/GLTFLoader';
import { SkeletonUtils } from 'three-stdlib/utils/SkeletonUtils';
import {
  Mesh,
  MeshStandardMaterial,
  MeshStandardMaterialParameters,
  Vector2,
} from 'three';
 
interface HeadModelProps {
  facing: number; // 1 == forwards, -1 == backwards.
}

// Scale head mesh to ear coord scale.
const _headScale = 1000; // Meters -> Millimeters

// Materials are replaced on load with these params.
const _headMaterialParams: MeshStandardMaterialParameters = {
  color: 0x555555,
  emissive: 0x222222,
  metalness: 0.0,
  roughness: 0.7,
};

// Ear center reference point - in meters, mesh coords.
const _earCenter = [-0.004, 1.60432, 0.08201];

// Reference points on ear mesh used to calculate ear facing.
const _earP1 = [-0.08087, -0.01058, 1.63195];
const _earP2 = [-0.08828, 0.01459, 1.61495];
const _earFacing = new Vector2(
  _earP2[1] - _earP1[1],
  _earP2[0] - _earP1[0]
).angle();

function Model(props: HeadModelProps) {
  const loader = useLoader(GLTFLoader, './human-head.glb');
  const model = useMemo( 
    () => SkeletonUtils.clone(loader.scene),
    [loader.scene]
  );
  const earCenterMeters = [
    props.facing * _earCenter[0],
    _earCenter[1],
    _earCenter[2],
  ];
  const earFacing = (props.facing * Math.PI) / 2;

  // Replace material so ambient light affects the model.
  useEffect(() => {
    model.traverse((node) => {
      if (node instanceof Mesh) {
        const mesh = node as Mesh;
        const material = new MeshStandardMaterial(_headMaterialParams);

        mesh.material = material;
        mesh.material.needsUpdate = true;
      }
    });
  }, [model]);

  const containerYRotation = -_earFacing * props.facing + Math.PI;

  return (
    <group rotation={[0, containerYRotation, 0]}>
      <group
        position={[
          -earCenterMeters[0] * _headScale,
          -earCenterMeters[1] * _headScale,
          -earCenterMeters[2] * _headScale,
        ]}
        rotation={[0, -earFacing, 0]}
        scale={_headScale}
      >
        <primitive object={model} />
      </group>
    </group>
  );
}

export default function HeadModel(props: HeadModelProps) {
  return (
    <Suspense fallback={null}>
      <Model {...props} />
    </Suspense>
  );
}
