import React from 'react';
import { Typography } from '@mui/material';
import { ScreenEdge, SideOfHead } from '../Structs/AppData';
import { SceneOverlay, SceneOverlayProps } from './SceneOverlay';

interface EdgeMessageOverlayProps extends SceneOverlayProps {
  nearScreenEdge: ScreenEdge;
  sideOfHead: SideOfHead;
}

export default function EdgeMessageOverlay(props: EdgeMessageOverlayProps) {
  const {
    nearScreenEdge,
    sideOfHead,
    ...rest
  } = props;
  let title = "Ear out of View"
  let direction = "";
  switch (nearScreenEdge) {
    case 'Near': {
      title = "Too Close to Ear"
      direction = 'away from your ear'
      break
    } case 'Far': {
      title = "Too Far from Ear"
      direction = 'towards your ear'
      break
    } case 'Top': {
      direction = 'upwards'
      break
    } case 'Bottom': {
      direction = 'downwards'
      break
    } case 'Left': {
      const tiltKeyword = (sideOfHead === 'left') ? 'back' : 'front';
      direction = `towards the ${tiltKeyword} of your head`
      break
    } case 'Right': {
      const tiltKeyword = (sideOfHead === 'left') ? 'front' : 'back';
      direction = `towards the ${tiltKeyword} of your head`
      break
    }
  }

  return (
    <SceneOverlay {...rest}>
      <div style={{margin: 'auto', display: 'block', textAlign: 'center'}}>
        <Typography variant="h3">
          {title}
        </Typography>
        <Typography variant="h4">
          Move your phone <strong>{direction}</strong> to bring your ear back into view.
        </Typography>
      </div>
    </SceneOverlay>
  )
}
