import { Vector3 } from 'three';

const _radToDeg = 180.0 / Math.PI;

// Ear-space coordinate utils.
// Note: Ear is at origin (0, 0, 0).
class EarUtils {
  // Distance to ear in mm.
  distanceTo(position: Vector3): number {
    return position.length();
  }

  // Left angle in degrees.
  leftAngle(position: Vector3): number {
    const angle = Math.atan2(position.x, -position.z);
    return angle * _radToDeg;
  }

  // Up angle in degrees.
  upAngle(position: Vector3): number {
    const mag = Math.sqrt(position.x * position.x + position.z * position.z);
    const angle = Math.atan2(position.y, mag);
    return angle * _radToDeg;
  }
}

export default new EarUtils();
