import React from 'react';
import { Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)({
  marginTop: 10,
  whiteSpace: 'pre-line',
});

interface SceneGuiProps {
  msg: string;
}

export default function SceneGui(props: SceneGuiProps) {
  const { msg } = props;
  return (
    <StyledAlert severity="info" variant="filled">
      {msg}
    </StyledAlert>
  );
}
