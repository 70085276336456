import React, { useState } from 'react';
import { Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import AppError from '../Errors/AppError';

const CodeComponent = styled('div')({
  display: 'flex',
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  height: 40, // Match height of text field.
}));

interface PreviewCodeFormProps {
  code: string;
  error: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  handleSubmitCodeForm: (event: React.FormEvent<HTMLFormElement>) => void;
}

export default function PreviewCodeForm(props: PreviewCodeFormProps) {
  const { 
    code,
    error,
    setCode,
    handleSubmitCodeForm,
  } = props;
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  // Note: Disabled is (was?) broken on Safari.
  // const submitDisabled = code.length === 0;

  const onClear = () => {
    setCode('');
  };
 
  const onMouseDownClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    try {
      handleSubmitCodeForm(event);
      setFieldErrors({}); // No more field errors.
    } catch (err) {
      if (err instanceof AppError) {
        setFieldErrors({ code: err.message });
      } else {
        throw err; // Rethrow.
      }
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <CodeComponent>
        <FormControl
          error={!!fieldErrors['code'] || !!error}
          variant="outlined"
          size="small"
          sx={{
            flex: 1,
            maxWidth: 250,
          }}
        >
          <InputLabel htmlFor="input-code">Enter Your Code Here</InputLabel>
          <OutlinedInput
            id="input-code"
            aria-describedby="input-code-error-text"
            label="Enter Your Code Here"
            value={code}
            onChange={(event) => setCode(event.target.value.toUpperCase())}
            autoFocus
            endAdornment={
              <InputAdornment position="end" sx={{ visibility: code.length > 0 ? 'visible' : 'hidden' }}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onClear}
                  onMouseDown={onMouseDownClear}
                  edge="end"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="input-code-error-text">{fieldErrors['code'] || error}</FormHelperText>
        </FormControl>
        <StyledButton
          type="submit"
          //disabled={submitDisabled}
          variant="contained"
          color="primary"
          disableElevation
        >
          Begin Viewing
        </StyledButton>
      </CodeComponent>
    </form>
  );
}
