import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const ParentComponent = styled('div')({
  position: 'absolute',
  left: 0,
  bottom: 0,
  padding: 10,
  whiteSpace: 'pre-wrap',  
});

interface LabelProps {
  children?: ReactNode;
}

export default function HudLabel(props: LabelProps) {
  return (
    <ParentComponent>
      {React.Children.map(props.children, (child) => (
        <Typography variant="body2">{child}</Typography>
      ))}
    </ParentComponent>
  );
}
