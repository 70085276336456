import React, { useState } from 'react'

interface BoundsState {
  width: number;
  height: number;
}

export default function useBounds<T extends HTMLElement>(ref: React.RefObject<T>) {
  const [bounds, setBounds] = useState<BoundsState>({ width: 0, height: 0 })
  React.useEffect(() => {
    function onResize() {
      const element = ref.current
      if (element) {
        setBounds({ width: element.offsetWidth, height: element.offsetHeight })
      }
    }

    // Set initial bounds.
    onResize();

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ref])
  return bounds
}
