import React, { Suspense, useMemo } from 'react';
import { Vector3, useLoader } from '@react-three/fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { Color, Object3D, Quaternion } from 'three';

interface TorusModelProps {
  filename: string;
  position: Vector3;
  orientation: Quaternion;
  scale: Vector3;
  opacity: number;
  color: Color;
  objectRef?: React.Ref<Object3D>;
}

/*
 * If you come across any issues getting materials to work, refer to:
 * https://github.com/pmndrs/react-three-fiber/discussions/897#discussioncomment-247458
 */

function Model(props: TorusModelProps) {
  const {
    filename,
    position,
    orientation,
    scale,
    opacity,
    color,
    objectRef
  } = props;
  const torusLoader = useLoader(STLLoader, filename);
  const torus = useMemo(() => torusLoader.clone(), [torusLoader]);

  return (
    <group
      ref={objectRef}
      position={position}
      scale={scale}
      quaternion={orientation}
      renderOrder={50}
    >
      <mesh>
        <primitive object={torus} attach="geometry" />
        <meshStandardMaterial
          color={color}
          transparent
          opacity={opacity}
          depthTest={false}
          depthWrite={false} // Let CylinderModel draw over us.
        />
      </mesh>
    </group>
  );
}

export default function TorusModel(props: TorusModelProps) {
  return (
    <Suspense fallback={null}>
      <Model {...props} />
    </Suspense>
  );
}
