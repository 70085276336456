type FlowMode = 'Center' | 'Target' | 'TooFar' | 'TooClose';

export function flowText(mode: FlowMode) {
  switch (mode) {
    case 'Center':
      return 'Keep the two crosshairs centered';
    case 'Target':
      return 'Line up all three crosshairs';
    case 'TooFar':
      return 'Move phone away from the ear';
    case 'TooClose':
      return 'Move phone toward the ear';
  }
}

export type { FlowMode as default };
