import { MutableRefObject, useCallback, useRef } from 'react';

// Returns function that finds the delta between old/new values when called.
export default function useDelta(count: MutableRefObject<number>) {
  const lastCount = useRef<number>(count.current);
  return useCallback(() => {
    const difference = count.current - lastCount.current;
    lastCount.current = count.current;
    return difference;
  }, [count])
}
