
enum AppEvent {
  connecting = "connecting",
  connected = "connected",
  connectionCancelled = "connectionCancelled",
  statusChanged = "statusChanged",
  poseChanged = "poseChanged"
}

export default AppEvent;
